import { useState, useRef, useEffect } from "react";
import {
  formatDateToDateOnly,
  formatDateToTimeOnly,
} from "../utils/dateFormatter";
import APIRoutes from "../api/apiRoutes";
import axios from "axios"; // You might need to install axios if not already done: npm install axios

const ChatInfoComments = ({ chatId, autoScrollEnabled, maxHeight }) => {
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const adminToken = localStorage.getItem("accessToken");
  // Create a ref for the message container
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        // Fetch the list of chats for the Support Account
        const response = await axios.get(
          `${APIRoutes.adminGetComments}/${chatId}`,
          {
            headers: {
              Authorization: adminToken,
            },
          }
        );
        setComments(response.data.comments);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchComments();
  }, []); // Empty dependency array ensures this runs only once on component mount

  useEffect(() => {
    // Scroll to the bottom when the component mounts
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const createComment = async () => {
    try {
      const response = await fetch(`${APIRoutes.adminAddComment}/${chatId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({ content: newComment }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.newComment) {
          setComments((prevComments) => [...prevComments, data.newComment]);
        }
        setNewComment("");
      } else {
        console.error("Failed to create comment");
      }
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  return (
    <div className="">
      <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
        <h2 className="font-semibold mx-1">Comments:</h2>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {comments.map((comment, index) => (
            <li key={index}>
              <div>
                <p className="text-xs mx-1">
                  <span className="font-bold">{comment.author}:</span>{" "}
                  {formatDateToDateOnly(comment.timestamp)}{" "}
                  {formatDateToTimeOnly(comment.timestamp)}
                </p>
                <p
                  style={{ whiteSpace: "pre-line" }}
                  className="bg-gray-300 p-2 rounded-lg mb-2 mx-1"
                >
                  {comment.content}
                </p>
              </div>
            </li>
          ))}
          <li ref={scrollRef}></li>
        </ul>
      </div>
      <div className="flex items-end mt-4 mb-10">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="border p-2 mb-1 ml-1 w-full rounded"
          placeholder="Add a comment..."
        />
        <button
          onClick={createComment}
          className="border border-blue-200 rounded-md m-1 px-1"
        >
          Add comment
        </button>
      </div>
    </div>
  );
};

export default ChatInfoComments;
