import React from "react";
import { Link } from "react-router-dom";
import { BsClock, BsBatteryFull, BsCurrencyDollar } from "react-icons/bs";
import { FaRegSquareCheck } from "react-icons/fa6";
import { PiListChecksDuotone } from "react-icons/pi";

const LandingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-fit bg-blue-950 text-white">
      <div className="flex flex-col items-center justify-center mt-32">
        <img
          className="w-60"
          alt="long-white-logo"
          src="/helper-logos/square-white.png"
        ></img>{" "}
        <div className="text-4xl font-bold text-center">
          Generate test plans within hours, not days.
        </div>
        <p className="text-lg mb-8">
          Helping QA engineers automate their manual work using power of AI.
        </p>
        <Link
          to="/login"
          className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
        >
          Create your test plan today!
        </Link>
      </div>
      <div className="mt-32 w-1/2">
        <div className="text-3xl font-bold text-center mb-10">Features</div>
        <div className="grid lg:grid-cols-3 text-center text-orange-400 space-x-">
          <div className="flex flex-col items-center gap-4 border-2 rounded-xl p-4 mx-2">
            <BsClock size={50} />
            <div className="text-white">Save time</div>
          </div>
          <div className="flex flex-col items-center gap-4 border-2 rounded-xl p-4 mx-2">
            <BsCurrencyDollar size={50} />
            <div className="text-white">Save money</div>
          </div>
          <div className="flex flex-col items-center gap-4 border-2 rounded-xl px-8 py-4 mx-2">
            <BsBatteryFull size={50} />
            <div className="text-white">Save energy</div>
          </div>{" "}
        </div>
      </div>
      <Link
        to="/login"
        className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 mt-10"
      >
        Create your test plan today!
      </Link>
      <div className="mt-20 mb-12 w-1/2">
        <div className="text-3xl font-bold text-center">Pricing</div>
        <div className=" items-stretch ">
          {/* <div className="mt-8 border-2 border-orange-400 rounded-xl px-4 py-2 flex flex-col gap-2 items-center text-xl  h-full">
            <div className="text-2xl text-orange-400">Free Plan</div>
            <div>7 days trial for free</div>
            <div className="flex py-auto">
              <span className="mt-1 mr-2">
                <PiListChecksDuotone size={20} />
              </span>
              All Standard Plan features
            </div>
          </div> */}
          <div className="col-start-2 mt-8 border-2 border-orange-400 rounded-xl px-4 py-2 flex flex-col gap-2 items-center text-xl  h-full">
            <div className="text-2xl text-orange-400">Standard Plan</div>
            <div>$9.99/month</div>
            <PlanFeature text="Unlimited testplans a month" />
            <PlanFeature text="Priority support" />
            <PlanFeature text="Includes 7 days trial period" />
          </div>
        </div>
      </div>
      <Link
        to="/login"
        className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 mt-6 mb-14"
      >
        Create your test plan today!
      </Link>
      <div className="text-gray-600">Copyright QA-Helper App</div>
    </div>
  );
};
const PlanFeature = ({ text }) => {
  return (
    <div className="flex">
      <span className="mt-1 mr-2">
        <FaRegSquareCheck size={20} />
      </span>
      {text}
    </div>
  );
};
export default LandingPage;
