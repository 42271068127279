import React from "react";
import { formatDateToDateOnly } from "../utils/dateFormatter";
const StatisticsTable = ({ dailyStatistics }) => {
  // Calculate totals for each metric
  // Calculate the difference between the first and last columns for each metric
  const differenceUsersCreated =
    dailyStatistics.length > 0
      ? dailyStatistics[dailyStatistics.length - 1].usersCreated -
        dailyStatistics[0].usersCreated
      : 0;
  const differenceTrialSubscriptions =
    dailyStatistics.length > 0
      ? dailyStatistics[dailyStatistics.length - 1].trialSubscriptions -
        dailyStatistics[0].trialSubscriptions
      : 0;
  const differenceRegularSubscriptions =
    dailyStatistics.length > 0
      ? dailyStatistics[dailyStatistics.length - 1].regularSubscriptions -
        dailyStatistics[0].regularSubscriptions
      : 0;
  const differenceCanceledSubscriptions =
    dailyStatistics.length > 0
      ? dailyStatistics[dailyStatistics.length - 1].canceledSubscriptions -
        dailyStatistics[0].canceledSubscriptions
      : 0;
  const differenceRevenue =
    dailyStatistics.length > 0
      ? dailyStatistics[dailyStatistics.length - 1].revenue -
        dailyStatistics[0].revenue
      : 0;

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Metric\Date
            </th>
            {dailyStatistics.map((statistic) => (
              <th
                key={statistic._id}
                className="px-6 py-3 text-left text-xs  text-gray-500 uppercase tracking-wider font-bold"
              >
                {formatDateToDateOnly(statistic.date)}
              </th>
            ))}
            <th className="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider">
              Total
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              Users Created
            </td>
            {dailyStatistics.map((statistic) => (
              <td
                key={`${statistic._id}-usersCreated`}
                className="px-6 py-4 whitespace-nowrap"
              >
                {statistic.usersCreated}
              </td>
            ))}
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              {differenceUsersCreated}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              Trial Subscriptions
            </td>
            {dailyStatistics.map((statistic) => (
              <td
                key={`${statistic._id}-trialSubscriptions`}
                className="px-6 py-4 whitespace-nowrap"
              >
                {statistic.trialSubscriptions}
              </td>
            ))}
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              {differenceTrialSubscriptions}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              Regular Subscriptions
            </td>
            {dailyStatistics.map((statistic) => (
              <td
                key={`${statistic._id}-regularSubscriptions`}
                className="px-6 py-4 whitespace-nowrap"
              >
                {statistic.regularSubscriptions}
              </td>
            ))}
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              {differenceRegularSubscriptions}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              Canceled Subscriptions
            </td>
            {dailyStatistics.map((statistic) => (
              <td
                key={`${statistic._id}-canceledSubscriptions`}
                className="px-6 py-4 whitespace-nowrap"
              >
                {statistic.canceledSubscriptions}
              </td>
            ))}
            <td className="px-6 py-4 whitespace-nowrap  font-bold">
              {differenceCanceledSubscriptions}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-nowrap font-bold">Revenue</td>
            {dailyStatistics.map((statistic) => (
              <td
                key={`${statistic._id}-revenue`}
                className="px-6 py-4 whitespace-nowrap"
              >
                {statistic.revenue}
              </td>
            ))}
            <td className="px-6 py-4 whitespace-nowrap font-bold">
              {differenceRevenue}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StatisticsTable;
