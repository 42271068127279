import React from "react";
import { BsChatDots, BsShieldLock, BsFiletypeDoc } from "react-icons/bs";
import { GoTasklist } from "react-icons/go";
import { MdSdStorage } from "react-icons/md";

const AboutContent = () => {
  return (
    <div className="bg-white p-8 h-full rounded-lg">
      <div className="mb-8 text-center">
        <h1 className="text-4xl font-bold mb-4">About QA Helper App</h1>
        <p className="text-lg mb-4">
          QA Helper is a tool designed to assist QA engineers in automating
          their manual work when creating test plans. It provides a
          user-friendly interface for managing and organizing test plans, making
          the testing process more efficient and streamlined.
        </p>
      </div>

      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4 text-center">Key Features</h2>
        <div className="grid grid-cols-2 gap-4 text-center">
          <Feature
            icon={<GoTasklist size={30} />}
            title="Generate Test Plans"
            description="Break down the test plan into manageable chapters, making the process more organized and enjoyable."
          />
          <Feature
            icon={<MdSdStorage size={30} />}
            title="Store Test Plans"
            description="Access all your test plans in one place, ensuring easy retrieval and management."
          />
          <Feature
            icon={<BsFiletypeDoc size={30} />}
            title="Generate, Edit, Store, and Download"
            description="Quickly generate, edit, store, and download test plans in various versions, tailored to your needs."
          />
          <Feature
            icon={<BsChatDots size={30} />}
            title="Integrated Live Chat Support"
            description="Connect with support instantly for feedback and improvements, ensuring every user request is addressed."
          />
          <div className="col-span-2 px-52">
            <Feature
              icon={<BsShieldLock size={30} />}
              title="Security"
              description="Log in securely with your social media account, protecting sensitive information."
            />
          </div>
        </div>
      </div>

      <div className="my-16">
        <h2 className="text-2xl font-bold mb-4 text-center px-8">
          About the Author
        </h2>
        <p className="text-lg mb-4 text-center">
          QA Helper is created by Danil Klimentev, a QA Automation Engineer with
          a passion for improving the testing workflow. The goal is to make
          testing more accessible and enjoyable for QA engineers by developing a
          user-centric SaaS solution.
        </p>
        <div className="grid grid-col-4">
          <blockquote className="text-lg my-3 col-span-3 px-10 italic text-gray-600 text-center">
            During a period when tasked with creating a test plan, I found it
            unappealing. It was a very lonely journey to create one, so this app
            was born out of a desire to retain passion for testing even while
            performing such tasks. The goal was to make the process enjoyable
            and sometimes even fun. Let me know if I achieved it ;)
          </blockquote>

          {/* Placeholder for the image */}
          <img
            src="helper-logos/founder-about.jpg" // Replace with the actual path to your image
            alt="Danil Klimentev"
            className="rounded-full w-32 mr-12 mb-4 col-span-1 col-start-4 flex self-center"
          />
        </div>

        <p className="text-lg text-center mt-4">
          If you have any feedback or suggestions, feel free to reach out to
          Danil Klimentev by contacting Support.
        </p>
      </div>
    </div>
  );
};
const Feature = ({ icon, title, description }) => (
  <div className="my-1 border p-4 rounded-xl border-orange-300 hover:bg-orange-200 hover:text-gray-600">
    <div className="flex items-center justify-center mb-2 text-orange-500">
      {icon}
    </div>
    <div>
      <strong className="text-orange-500">{title}</strong>{" "}
    </div>
    <span className="ml-2">{description}</span>
  </div>
);

export default AboutContent;
