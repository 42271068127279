import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// Assuming you have a route for the "Unauthorized" page
const UNAUTHORIZED_PAGE = "/login";
const BILLING_PAGE = "/billing"; // Add the billing page route

const useAxiosInterceptors = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Axios interceptor is applied");

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          console.log("Response Headers:", error.response.headers);

          // Check if the response is HTML (likely a login page)
          if (error.response.headers["content-type"].includes("text/html")) {
            // Redirect to the unauthorized page or login page
            navigate(UNAUTHORIZED_PAGE);
          } else {
            // Handle other types of 401 errors (possibly a JSON response)
            console.error("Unexpected 401 response:", error.response);
          }
        } else if (error.response && error.response.status === 403) {
          // Check for a 403 (Forbidden) response, assuming it indicates no active subscription
          console.log(
            "User is authorized, but no active subscription - Redirecting to billing page"
          );
          localStorage.removeItem("testPlans");

          // Redirect to the billing page
          navigate(BILLING_PAGE);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Remove the interceptor when the component unmounts
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);
};

export default useAxiosInterceptors;
