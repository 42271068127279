import React from "react";
import Chapter from "./Chapter";

const ChapterList = ({ testplan, testPlanId }) => {
  return (
    <div>
      {testplan.chapters.map((chapter, index) => (
        <Chapter
          chapter={chapter}
          testPlanId={testPlanId}
          chapterIndex={index}
        />
      ))}
    </div>
  );
};

export default ChapterList;
