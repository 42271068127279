import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Events, scrollSpy } from "react-scroll";
import classNames from "classnames";
import APIRoutes from "../api/apiRoutes";
import { useSocket } from "../context/SocketContext";
import { useUser } from "../context/UserDataContext";
import { IoMdMailUnread } from "react-icons/io";
import "../css/styles.css"; // Import the CSS file

const Header = ({ onProfileClick }) => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const { socket } = useSocket();
  const {
    userData,
    adminUnreadMessages,
    fetchAdminChatStatus,
    userUnreadMessages,
    setUserUnreadMessages,
  } = useUser();

  //When there is message from support - turn on the flag
  useEffect(() => {
    if (socket) {
      socket.on("support_message", (data) => {
        setUserUnreadMessages(true);
      });

      return () => {
        socket.off("support_message");
      };
    }
  }, [socket]);

  // Fetch admin chat statuses at regular intervals
  useEffect(() => {
    // Check if adminUnreadMessages is false before fetching
    if (!adminUnreadMessages && userData.role === "admin") {
      const intervalId = setInterval(() => {
        fetchAdminChatStatus(localStorage.getItem("accessToken"));
      }, 60000); // Fetch every 1 minute (60000 milliseconds)

      // Cleanup the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [fetchAdminChatStatus, adminUnreadMessages]);

  const linkStyle =
    "hover:underline hover:text-orange-500 active:text-orange-600";

  // Check if the current route is the login page and disable header
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/";
  if (isLoginPage) {
    return null;
  }

  const handleReadMessages = async () => {
    try {
      fetch(APIRoutes.supportReadChat, {
        method: "PATCH",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }).then((res) => {
        if (res.ok) {
          setUserUnreadMessages(false);
          // console.log("Chat marked as read.");
        }
      });
    } catch (err) {
      console.error("Error during marking chat as read:", err);
    }
  };

  const handleLogout = async () => {
    try {
      // Send a POST request to the server to logout
      fetch(APIRoutes.logout, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }).then((res) => {
        if (res.ok) {
          console.log("Logout successful");
          localStorage.removeItem("accessToken"); // Remove accessToken from localStorage
          window.location.href = "/"; // Redirect to the landing page
        } else {
          console.error("Logout failed");
        }
      });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const headerClass = classNames(
    "bg-gradient-to-l from-orange-500 from-5% via-orange-100 to-white ",
    {
      "h-24": !isScrolled, // Initial height
      "h-16 ": isScrolled, // Scrolled height
    }
  );

  const logoClass = classNames("mr-5", {
    "h-24": !isScrolled, // Initial height
    "h-16": isScrolled, // Scrolled height
  });

  return (
    <header className={headerClass}>
      <nav className="flex items-center space-x-5 text-lg">
        {/* Your logo component */}
        <Link to="/testplans" className="flex items-center">
          <img
            className={logoClass}
            alt="long-blue-logo"
            src="/helper-logos/long-blue.png"
          ></img>
          <span className={linkStyle}>My Testplans</span>
        </Link>
        {/* Navigation links */}
        <Link to="/about" className={linkStyle}>
          About QA Helper
        </Link>
        <button className={`${linkStyle} border-none`} onClick={onProfileClick}>
          Profile
        </button>
        <Link
          to="/support"
          className={linkStyle}
          onClick={() => handleReadMessages()}
        >
          Chat With Support{" "}
          {userUnreadMessages && (
            <span className="text-xs blink align-[7px]">
              {" "}
              New{" "}
              <span className="align-[2px]">
                <IoMdMailUnread className="text-xs inline " />
              </span>
            </span>
          )}
        </Link>

        {userData.role === "admin" && (
          <Link to="/admin" className={linkStyle}>
            Admin Panel
            {adminUnreadMessages && (
              <span className="text-xs blink align-[7px]">
                {" "}
                New{" "}
                <span className="align-[2px]">
                  <IoMdMailUnread className="text-xs inline " />
                </span>
              </span>
            )}
          </Link>
        )}

        {localStorage.getItem("accessToken") !== "" ? (
          // Render Logout button if user is logged in
          <button className={`${linkStyle} border-none`} onClick={handleLogout}>
            Logout
          </button>
        ) : (
          // Render Login button if user is not logged in
          <Link to="/login" className={`${linkStyle} border-none`}>
            Login
          </Link>
        )}
      </nav>
    </header>
  );
};
export default Header;
