import React, { useState, useCallback, useEffect } from "react";
import TextEditor from "./TextEditor";
import APIRoutes from "../api/apiRoutes";
import InfoIcon from "./modals/InfoIcon";
const TestPlanViewer = ({ testPlan }) => {
  const chaptersTogether = testPlan.chapters
    .map((chapter, index) => {
      return `${chapter.content}\n\n`;
    })
    .join("\n");

  const [testplanContent, setTestplanContent] = useState(chaptersTogether);
  const [draftContent, setDraftContent] = useState(""); // Store draft content
  const [selectedVersion, setSelectedVersion] = useState("initial");
  const accessToken = localStorage.getItem("accessToken");
  const handleTextEditorChange = (newContent) => {
    setTestplanContent(newContent);
  };

  const handleSave = useCallback(
    async (content) => {
      try {
        // Send the content to the backend to save as a draft
        const response = await fetch(
          `${APIRoutes.saveTestplanDraft.replace(":id", testPlan._id)}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ draftContent: content }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setDraftContent(data.draftContent);
        } else {
          console.error("Failed to save the draft.");
        }
      } catch (error) {
        console.error("Error saving the draft:", error);
      }
    },
    [accessToken, testPlan._id]
  );

  const fetchDraftContent = useCallback(async () => {
    try {
      // Fetch draft content from the backend based on the testPlan._id
      const response = await fetch(
        `${APIRoutes.getTestplan.replace(":id", testPlan._id)}`,
        {
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDraftContent(data.testplanDraft);
      } else {
        console.error("Failed to fetch the draft.");
      }
    } catch (error) {
      console.error("Error fetching the draft:", error);
    }
  }, [accessToken, testPlan._id]);

  useEffect(() => {
    // Fetch the draft content when the component mounts
    fetchDraftContent();
  }, [fetchDraftContent]);

  const handleSwitchVersion = (version) => {
    if (version === "initial") {
      setTestplanContent(chaptersTogether);
      setSelectedVersion("initial");
    } else if (version === "draft") {
      setTestplanContent(draftContent);
      setSelectedVersion("draft");
    }
  };

  return (
    <div className="">
      <div className="flex  ">
        <button
          className={`${
            selectedVersion === "initial"
              ? "bg-orange-500 text-white"
              : "bg-white text-orange-500 hover:bg-orange-200"
          } py-2 px-4 rounded-l border border-orange-500 mt-5 space-x-0`}
          onClick={() => handleSwitchVersion("initial")}
          disabled={selectedVersion === "initial"}
        >
          Initial Version
        </button>
        <button
          className={`${
            selectedVersion === "draft"
              ? "bg-orange-500 text-white"
              : "bg-white text-orange-500 hover:bg-orange-200"
          } py-2 px-4 rounded-r border border-orange-500 mt-5`}
          onClick={() => handleSwitchVersion("draft")}
          disabled={selectedVersion === "draft"}
        >
          Draft Version
        </button>
        <span className="ml-2 mt-6">
          <InfoIcon
            infoText={
              "Initial Version collects all the chapters together. When You click Save - Your current version will be saved in the Draft Version. After You refresh the page - Initial Version will return to initial state. Draft Version is a manually saved testplan - when You edit in Draft and Save it - no changes of Chapters'content will affect it untill You Save Initial Version."
            }
          />
        </span>
      </div>
      <div className="mt-[-57px]">
        <TextEditor
          initialText={testplanContent}
          onEditorContentChange={handleTextEditorChange}
          onSave={handleSave}
          projectName={testPlan.projectName}
        />
      </div>
    </div>
  );
};

export default TestPlanViewer;
