import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import APIRoutes from "../api/apiRoutes";
import {
  getDate,
  formatDateToDateOnly,
  formatDateToTimeOnly,
} from "../utils/dateFormatter";
import ChatInfoComments from "../components/ChatInfoComments";
import { useUser } from "../context/UserDataContext";
const ChatAdminPage = () => {
  const { chatId } = useParams();
  const [socket, setSocket] = useState(null);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [message, setMessage] = useState("");
  const userToken = localStorage.getItem("accessToken");
  const [chatWith, setchatWith] = useState("");
  // Sort messages based on dateTime
  const allMessages = [...receivedMessages, ...sentMessages].sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
  );
  // Create a ref for the message container
  const messageContainerRef = useRef(null);
  const { fetchAdminChatStatus } = useUser();

  useEffect(() => {
    const newSocket = io(APIRoutes.socket, {
      auth: {
        token: userToken, // Replace with the actual user ID
      },
    });

    newSocket.on("connect", () => {
      //console.log("Admin connected to server");
      // Emit user_join event when connected
      newSocket.emit("admin_join", newSocket.auth.token, chatId);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect(); // Disconnect when the component unmounts
    };
  }, []);

  // Function to fetch previous chat messages
  const fetchPreviousMessages = async () => {
    try {
      const response = await fetch(`${APIRoutes.adminGetMessages}/${chatId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const allMessages = data.messages.map((msg) => {
          return {
            ...msg,
          };
        });
        setchatWith(data.userDisplayName);
        setReceivedMessages(allMessages);
      } else {
        console.error("Failed to fetch chat messages");
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  useEffect(() => {
    // Fetch previous messages when the component mounts
    fetchPreviousMessages();
  }, []);

  // Function to scroll to the bottom of the message container
  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };
  // Use effect to scroll to the bottom whenever new messages are added
  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  const handleSendMessage = () => {
    const token = socket?.io?.opts?.auth?.token || "";
    const dateTime = new Date().toISOString();
    socket.emit("support_message", { token, message, chatId });
    // console.log(
    //   `Sent message from admin ${token}: ${message}. To chat ${chatId}`
    // );
    setSentMessages((prevMessages) => [
      ...prevMessages,
      { sender: "You", content: message, dateTime },
    ]);
    setMessage(""); // Clear the input field after sending the message
    const textarea = document.getElementById("chatTextarea");
    if (textarea) {
      textarea.style.height = "auto"; //adjust height back to 1 line
    }
  };

  const handleKeyPress = (e) => {
    // Check if "Enter" key is pressed without holding "Shift"
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior (e.g., submitting a form)
      handleSendMessage();
    }
  };
  const maxLines = 8;

  const handleInputHeight = (e) => {
    // Set the textarea height based on its scrollHeight
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";

    // Limit the number of visible lines
    const lines = e.target.value.split("\n").length;
    if (lines > maxLines) {
      e.target.style.overflowY = "auto"; // Enable vertical scrollbar
      e.target.style.height = `${maxLines * 1.5}em`; // Adjust the height to show the desired number of lines
    } else {
      e.target.style.overflowY = "hidden"; // Disable vertical scrollbar
    }
  };
  // Later in your component, you can listen for incoming messages
  useEffect(() => {
    if (socket) {
      socket.on(
        "user_message",
        ({ userDisplayName, message, chatId, dateTime }) => {
          // console.log(
          //   `Received message from user ${userDisplayName} in chat ${chatId}: ${message}.`
          // );
          setReceivedMessages((prevMessages) => [
            ...prevMessages,
            { sender: userDisplayName, content: message, dateTime },
          ]);
        }
      );

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off("user_message");
      };
    }
  }, [socket]); // The empty dependency array ensures that this effect runs only once

  return (
    <div className="container mx-auto p-4 grid grid-cols-4 grid-rows-1">
      <div className="col-span-3 max-w-md w-full mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl">
        <h2 className="text-2xl font-bold mb-4">Chat with {chatWith}</h2>
        <div
          className="message-container"
          ref={messageContainerRef} // Attach the ref to the message container
          style={{ maxHeight: "400px", overflowY: "auto" }} // Set a max height and enable vertical scrolling
        >
          {allMessages.map((msg, index) => (
            <div key={index}>
              {/* Check if the current message's date is different from the previous one */}
              {index === 0 ||
              getDate(allMessages[index - 1].dateTime) !==
                getDate(msg.dateTime) ? (
                <p className="text-center text-sm mb-2">
                  {formatDateToDateOnly(msg.dateTime)}
                </p>
              ) : null}

              <p
                className={`text-xs ${
                  msg.sender === "You" ? "text-right" : ""
                }`}
              >
                <span className="font-bold">{msg.sender}:</span>{" "}
                {formatDateToTimeOnly(msg.dateTime)}
              </p>
              <div
                className={`${
                  msg.sender === "You" ? "sent-message" : "received-message"
                } bg-gray-300 p-2 rounded-lg mb-2 max-w-fit ${
                  msg.sender === "You"
                    ? "text-right bg-orange-300 ml-auto"
                    : "mr-auto"
                }`}
                style={{ whiteSpace: "pre-line" }}
              >
                {msg.content}
              </div>
            </div>
          ))}
        </div>

        <div
          className="flex flex-col items-end mt-4"
          style={{ marginBottom: "16px" }} // Add margin to separate input and button
        >
          <textarea
            id="chatTextarea"
            rows="1" // Set the initial number of visible text lines
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              handleInputHeight(e);
            }}
            onKeyPress={handleKeyPress} // Handle key press events
            className="border p-2 w-full mb-2 resize-none" // Disable textarea resizing
          />
          <button
            onClick={() => {
              handleSendMessage();
              scrollToBottom(); // Scroll to the bottom after sending a message
            }}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Send Message
          </button>
        </div>
      </div>
      <div className="col-span-1 overflow-hidden flex items-end">
        <ChatInfoComments
          chatId={chatId}
          autoScrollEnabled={true}
          maxHeight={"400px"}
        />
      </div>
    </div>
  );
};

export default ChatAdminPage;
