import React, { useState, useEffect } from "react";
import axios from "axios";
import APIRoutes from "../api/apiRoutes";
import ChatInfo from "./ChatInfo";

const ChatList = () => {
  const [chats, setChats] = useState([]);
  const [displayChats, setDisplayChats] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("pick status to fetch");
  const adminToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axios.get(APIRoutes.adminGetChats, {
          headers: {
            Authorization: adminToken,
          },
        });

        setChats(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchChats();
  }, []); // Add filter as a dependency to re-fetch chats when the filter changes

  const handleDisplayChats = (status) => {
    const results = chats.filter((chat) => chat.status === status);
    setDisplayChats(results);
    setCurrentStatus(status);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">
        Chats in status &quot;{currentStatus}&quot;
      </h2>

      {/* Buttons to filter chats */}
      <div className="mb-4">
        <button
          className="border border-blue-200 rounded-md mr-2 px-2"
          onClick={() => handleDisplayChats("new")}
        >
          New
        </button>
        <button
          className="border border-blue-200 rounded-md mr-2 px-2"
          onClick={() => handleDisplayChats("in_progress")}
        >
          In Progress
        </button>
        <button
          className="border border-blue-200 rounded-md mr-2 px-2"
          onClick={() => handleDisplayChats("done")}
        >
          Done
        </button>
      </div>

      <ul>
        <div></div>

        {displayChats.map((chat) => (
          <ChatInfo key={chat._id} chat={chat} />
        ))}
      </ul>
    </div>
  );
};

export default ChatList;
