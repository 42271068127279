import React, { useState } from "react";

const DetailRow = ({ label, value, onSave, isEditable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleSave = async () => {
    await onSave(editedValue); // Save the edited value
    setIsEditing(false); // Exit editing mode
  };

  return (
    <div className="h-fit">
      {isEditable ? (
        isEditing ? (
          // Render input field and save icon when editing
          <div className="grid items-top">
            <span className="flex row-start-1 text-orange-500 font-bold">
              {label}:{" "}
              <span className="m-1 flex items-center">
                <img
                  src="/symbols/save-orange.png" // Adjust the path accordingly
                  alt="Save"
                  className="save-icon w-4 cursor-pointer"
                  onClick={handleSave}
                />
              </span>
            </span>

            <textarea
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
              className="border rounded border-blue-500 focus:outline-none focus:border-blue-700 w-full resize-none p-1"
              rows="5" // Set the initial number of visible text lines
            />
          </div>
        ) : (
          <div className="grid items-top">
            <span className="flex row-start-1 text-orange-500 font-bold">
              {label}:{" "}
              <span className="flex row-start-1 items-left">
                <img
                  src="/symbols/pencil-edit-orange.png" // Replace with your pencil icon URL
                  alt="Edit"
                  className="h-4 w-4 cursor-pointer ml-2 mt-1"
                  onClick={() => setIsEditing(true)}
                />
              </span>
            </span>
            <div className="flex-rows-1 row-start-2">{value}</div>
          </div>
        )
      ) : (
        <span className="h-fit">
          <span className="text-orange-500 font-bold">{label}:</span>{" "}
          <span>{value}</span>
          <br />
        </span>
      )}
    </div>
  );
};

export default DetailRow;
