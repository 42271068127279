import React from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const LoadingSpinner = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <span className="sweet-loading mt-2">
      <ClipLoader color={"orange"} loading={true} css={override} size={15} />
    </span>
  );
};

export default LoadingSpinner;
