import React, { useState } from "react";
import { Link } from "react-router-dom";
import TestPlanInfo from "./TestplanInfo";
import DeleteModal from "./modals/DeleteModal";
import { useUser } from "../context/UserDataContext";

const TestplanList = ({ testPlans, onDeleteTestPlan }) => {
  const [showModal, setShowModal] = useState(false);
  const [testplanIdToDelete, setTestplanIdToDelete] = useState(null);
  const [testplanNameToDelete, setTestplanNameToDelete] = useState(null);

  const handleDelete = (testplan) => {
    setTestplanIdToDelete(testplan._id);
    setTestplanNameToDelete(testplan.projectName);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = (testPlanId) => {
    onDeleteTestPlan(testPlanId);
    setShowModal(false);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <h3 className="mb-4 text-2xl font-bold text-gray-800">My Testplans</h3>
      {testPlans.length === 0 ? (
        <p>You have no testplans yet.</p>
      ) : (
        testPlans.map((testPlan, index) => (
          <div key={index} className="mb-4 flex items-center ">
            <Link to={`/testplans/${testPlan._id}`} className="flex-grow">
              <TestPlanInfo testPlan={testPlan} isLink={true}></TestPlanInfo>
            </Link>

            <button
              onClick={() => handleDelete(testPlan)}
              className="ml-2 border border-orange-700/50 bg-orange-600 hover:bg-red-600 active:bg-red-700 text-white py-2 px-4 rounded"
            >
              Delete
            </button>
            {showModal && (
              <DeleteModal
                text={`Are you sure you want to delete ${testplanNameToDelete}?`}
                onCancel={handleCancel}
                onConfirm={() => handleConfirmDelete(testplanIdToDelete)}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default TestplanList;
