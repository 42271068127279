import React, { useState } from "react";
import APIRoutes from "../api/apiRoutes";
import { formatDate } from "../utils/dateFormatter";
import DeleteModal from "./modals/DeleteModal";
import SubscriptionDetails from "./SubscriptionDetails";
const accessToken = localStorage.getItem("accessToken");

const UsersTable = ({ users }) => {
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userNameToDelete, setUserNameToDelete] = useState(null);

  const handleDelete = (user) => {
    setUserIdToDelete(user._id);
    setUserNameToDelete(user.displayName);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = (userId) => {
    deleteUser(userId);
    setShowModal(false);
  };

  const deleteUser = async (userId) => {
    try {
      // Send a request to delete the test plan by its ID
      const response = await fetch(`${APIRoutes.adminUserDelete}/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Failed to delete the test plan.");
      }
    } catch (error) {
      console.error("Error deleting the test plan:", error);
    }
  };

  const changeRole = async (userId, newRole) => {
    try {
      // Send a request to delete the test plan by its ID
      const response = await fetch(`${APIRoutes.adminChangeRole}/${userId}`, {
        method: "PATCH",
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          role: newRole,
        }),
      });

      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Failed to delete the test plan.");
      }
    } catch (error) {
      console.error("Error deleting the test plan:", error);
    }
  };
  const handleChangeRoleClick = (userId, newRole) => {
    changeRole(userId, newRole);
  };

  return (
    <div className="grid grid-cols-auto gap-4">
      {users.map((user) => (
        <div key={user._id} className="border p-4 rounded">
          <div>
            <strong>Display Name:</strong> {user.displayName}
          </div>
          <div>
            <strong>Email:</strong> {user.email}
          </div>
          <div>
            <strong>First Name:</strong> {user.firstName}
          </div>
          <div>
            <strong>Last Name:</strong> {user.lastName}
          </div>
          <div>
            <strong>Image:</strong> {user.image}
          </div>
          <div>
            <strong>Created At:</strong> {formatDate(user.createdAt)}
          </div>
          {user.subscriptions.map((subscription) => (
            <SubscriptionDetails
              subscription={subscription}
            ></SubscriptionDetails>
          ))}

          <div>
            <strong>Company Name:</strong> {user.companyName}
          </div>
          <div>
            <strong>Test Plans:</strong> {user.testPlans}
          </div>

          <div>
            <strong>Role:</strong> {user.role}
          </div>
          <button
            className="border border-orange-700/50 bg-orange-600 hover:bg-red-600 active:bg-red-700 text-white py-1 px-2 rounded"
            onClick={() => handleDelete(user)}
          >
            Delete
          </button>
          {showModal && (
            <DeleteModal
              text={`Are you sure you want to delete ${userNameToDelete}?`}
              onCancel={handleCancel}
              onConfirm={() => handleConfirmDelete(userIdToDelete)}
            />
          )}
          {user.role === "user" ? (
            <button
              className="border border-yellow-600 bg-yellow-400 hover:bg-yellow-600 active:bg-yellow-700 text-white py-1 px-2 rounded"
              onClick={() => handleChangeRoleClick(user._id, "admin")}
            >
              Make Admin
            </button>
          ) : (
            <button
              className="border border-yellow-400 bg-yellow-600 hover:bg-yellow-600 active:bg-yellow-700 text-white py-1 px-2 rounded"
              onClick={() => handleChangeRoleClick(user._id, "user")}
            >
              Make User
            </button>
          )}

          {/* Add more divs for additional properties */}
        </div>
      ))}
    </div>
  );
};

export default UsersTable;
