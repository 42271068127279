// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import APIRoutes from "../api/apiRoutes";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const userToken = localStorage.getItem("accessToken");
    const newSocket = io(APIRoutes.socket, {
      auth: {
        token: userToken,
      },
    });

    newSocket.on("connect", () => {
      console.log("Connected to server");
      newSocket.emit("user_join", newSocket.auth.token);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
