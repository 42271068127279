import React, { useState } from "react";

const EditableInfo = ({ header, userData, handleFieldChange, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(userData);

  // Function to toggle the editing state
  const toggleEdit = () => {
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const change = (e) => {
    handleFieldChange(e.target.value);
    setEditedValue(e.target.value);
  };

  const handleSave = async () => {
    await onSave(editedValue); // Pass the edited value to onSave
    toggleEdit(); // Toggle editing state
  };

  return (
    <div className="text-orange-500 font-semibold items-center w-full">
      <div className="">{header}:</div>
      {isEditing ? (
        <div className="text-gray-600 font-normal flex flex-row col-span-2 items-center justify-between">
          <div className="flex flex-row items-center">
            <input
              className="border border-orange-300 rounded p-1 active:border-orange-300"
              type="text"
              value={editedValue}
              onChange={(e) => change(e)}
              style={{ outline: "none" }}
            />
          </div>
          <div>
            <img
              src="/symbols/save-orange.png" // Adjust the path accordingly
              alt="Save"
              className="save-icon w-3 cursor-pointer"
              onClick={handleSave}
            />{" "}
          </div>
        </div>
      ) : (
        <div className="text-gray-600 font-normal flex flex-row col-span-2 items-center justify-between">
          <div className="flex flex-row items-center border-b-2 border-orange-200">
            {userData}
          </div>
          {handleFieldChange && ( //if handleFieldChange is null, to not render button to edit field (email)
            <div>
              <img
                src="/symbols/pencil-edit-orange.png" // Adjust the path accordingly
                alt="Edit"
                className="edit-icon w-3 cursor-pointer"
                onClick={toggleEdit}
              />{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableInfo;
