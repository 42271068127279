import React, { createContext, useContext, useEffect, useState } from "react";
import APIRoutes from "../api/apiRoutes";
import useAxiosInterceptors from "../components/login/Unauthorized";
import axios from "axios";

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  useAxiosInterceptors();
  const [testPlans, setTestPlans] = useState(
    JSON.parse(localStorage.getItem("testPlans")) || []
  );
  const [userUnreadMessages, setUserUnreadMessages] = useState(false);
  const [adminUnreadMessages, setAdminUnreadMessages] = useState(false);
  const [userData, setUserData] = useState({
    // Define the structure of user data
    image: "/path/to/default-photo.png", // Provide a default photo path
    displayName: "",
    email: "",
    companyName: "",
    activeSubscription: null,
    role: "",
    isTrial: false,
  });

  useEffect(() => {
    fetchUserData(accessToken);
  }, []);

  useEffect(() => {
    fetchChatStatus(accessToken);

    if (userData.role === "admin") {
      fetchAdminChatStatus(accessToken);
    }
  }, [userData]);

  //   useEffect(() => {
  //     console.log("User has unread messages: " + userUnreadMessages);
  //   }, [userUnreadMessages]);
  //   useEffect(() => {
  //     console.log("Admin has unread messages: " + adminUnreadMessages);
  //   }, [adminUnreadMessages]);

  //   useEffect(() => {
  //     console.log("Updated testPlans:", testPlans);
  //   }, [testPlans]);

  // Fetch the list of test plans from the server
  const fetchTestPlans = async (accessToken) => {
    axios
      .get(APIRoutes.getTestplans, {
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setTestPlans(response.data);
        localStorage.setItem("testPlans", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error("Error fetching test plans:", error);
      });
  };

  const fetchUserData = async (accessToken) => {
    try {
      const response = await fetch(APIRoutes.getUser, {
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data); // Set user data in state
        checkIsTrial(data);
      } else {
        console.error("Failed to fetch user data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const checkIsTrial = (userData) => {
    if (userData && userData.activeSubscription) {
      const nextBilling = new Date(
        userData.activeSubscription.billing_info.next_billing_time
      );
      const startDate = new Date(userData.activeSubscription.start_time);
      const difference = (nextBilling - startDate) / (1000 * 60 * 60 * 24);
      const result = difference < 15;
      setUserData((prevUserData) => ({
        ...prevUserData,
        isTrial: result,
      }));
    }
  };
  const fetchChatStatus = async (accessToken) => {
    try {
      const response = await fetch(APIRoutes.getUnreadMessages, {
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(
          "Is Chat Read by user? - " + JSON.stringify(data.isReadByUser)
        );
        console.log("Unread messages for user - " + !data.isReadByUser);
        setUserUnreadMessages(!data.isReadByUser);
      } else {
        console.error("Failed to fetch chat statuses:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching chat statuses:", error);
    }
  };
  const fetchAdminChatStatus = async (accessToken) => {
    try {
      const response = await fetch(APIRoutes.adminSupportChatStatuses, {
        method: "GET",
        headers: {
          Authorization: accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Admin new chat - " + data.hasNewChat);
        setAdminUnreadMessages(data.hasNewChat);
      } else {
        console.error("Failed to fetch chat statuses:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching chat statuses:", error);
    }
  };

  return (
    <UserDataContext.Provider
      value={{
        userData, //userdata
        setUserData,
        fetchUserData,
        testPlans, //testplans
        setTestPlans,
        fetchTestPlans,
        adminUnreadMessages, //notifications Admin Panel
        setAdminUnreadMessages,
        fetchAdminChatStatus,
        userUnreadMessages, //notifications Chat With Support
        setUserUnreadMessages,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserDataContext);
};
