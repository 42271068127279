const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-GB",
    options
  );
  return formattedDate.replace(/\//g, ".");
};

const getDate = (dateTime) => {
  const dateObject = new Date(dateTime);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return dateObject.toLocaleString("en-US", options);
};

// Function to format the date to a custom format (15, July 2023)
const formatDateToDateOnly = (dateTime) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(dateTime).toLocaleDateString("en-US", options);
};
const formatDateToTimeOnly = (dateTime) => {
  return new Date(dateTime).toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};
const formatDateForChatInfo = (dateTime) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  try {
    return new Date(dateTime).toLocaleDateString("en-US", options);
  } catch {
    return null;
  }
};

module.exports = {
  formatDate,
  getDate,
  formatDateToDateOnly,
  formatDateToTimeOnly,
  formatDateForChatInfo,
};
