import React, { useRef, useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { saveAs } from "file-saver";
import InfoIcon from "./modals/InfoIcon";
const TextEditor = ({
  initialText,
  onEditorContentChange,
  onSave,
  projectName,
}) => {
  const editorRef = useRef(null);
  const [editorValue, setEditorValue] = useState(initialText);

  useEffect(() => {
    // Update the editor's content when initialText prop changes
    setEditorValue(initialText);
  }, [initialText]);

  const exportToWord = useCallback(async () => {
    const quillToWord = require("quill-to-word");
    const quillToWordConfig = {
      exportAs: "blob",
    };
    const docAsBlob = await quillToWord.generateWord(
      editorRef.current.getEditor().getContents(),
      quillToWordConfig
    );
    saveAs(docAsBlob, `${projectName} Testplan.docx`);
  }, []);

  return (
    <div className="w-full">
      {projectName && (
        <div className="flex justify-end mb-1">
          <span className="mr-2 mt-6">
            <InfoIcon
              infoText={"You can export your document in .docx format anytime."}
            />
          </span>
          <button
            className="border border-blue-400 bg-white text-blue-400 hover:bg-blue-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4 mr-1 "
            onClick={exportToWord}
          >
            Export to Word
          </button>
          <button
            className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
            onClick={() => onSave(editorValue)}
          >
            Save
          </button>
        </div>
      )}
      <ReactQuill
        className="border border-blue-400 rounded"
        ref={editorRef}
        value={editorValue}
        onChange={(content) => {
          setEditorValue(content);
          onEditorContentChange(content);
        }}
      />
      {projectName && (
        <button
          className="mr-1 mb-1 border border-blue-400 bg-white text-blue-400 hover:bg-blue-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
          onClick={exportToWord}
        >
          Export to Word
        </button>
      )}
      <button
        className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
        onClick={() => onSave(editorValue)}
      >
        Save
      </button>
      <span className="mx-2">
        <InfoIcon
          infoText={
            "Once You're happy with layout, formatting and text inside the editor - click Save and current version of the content inside will be saved."
          }
        />
      </span>
    </div>
  );
};

export default TextEditor;
