import React, { useState, useEffect } from "react";
import APIRoutes from "../api/apiRoutes";
import UsersTable from "../components/UsersTable";
import ChatList from "../components/ChatList";
import StatisticsTable from "../components/StatisticsTable";
const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [dailyStatistics, setDailyStatistics] = useState([]);
  const [period, setPeriod] = useState("week"); // Default period is set to "week"

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    // Fetch users only when showAllUsers is true
    const fetchUsers = async () => {
      try {
        const usersResponse = await fetch(APIRoutes.adminGetUsers, {
          method: "GET",
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (usersResponse.ok) {
          const usersData = await usersResponse.json();
          setUsers(usersData);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (showAllUsers) {
      fetchUsers();
    }
  }, [accessToken, showAllUsers]);

  useEffect(() => {
    // Fetch users only when showAllUsers is true
    const fetchDailyMetrics = async () => {
      try {
        const response = await fetch(
          `${APIRoutes.adminGetStatistics}?period=${period}`,
          {
            method: "GET",
            headers: {
              Authorization: `${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setDailyStatistics(data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchDailyMetrics();
  }, [period]);

  // Function to filter users based on search term
  const handleFindUser = () => {
    const results = users.filter(
      (user) =>
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  };

  // Function to show all users
  const handleShowAll = () => {
    setSearchResults([]);
    setShowAllUsers(true); // Set showAllUsers to true when the button is clicked
  };

  return (
    <div className="m-4 p-4">
      <h2 className="text-2xl font-bold mb-4">Admin Page</h2>
      <h2 className="text-xl font-bold mb-4">Statistics</h2>
      <div>
        <label htmlFor="period">Select Period: </label>
        <select
          id="period"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <option value="day">Yesterday</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
          <option value="all">All Time</option>
        </select>
      </div>
      <StatisticsTable dailyStatistics={dailyStatistics} />

      <div>
        <ChatList />
      </div>

      {/* Users Block */}
      <div>
        <h2 className="text-2xl font-bold my-4">Users</h2>
        {/* Search Block */}
        <div>
          <input
            className="w-1/4 h-8 border border-blue-200 rounded-md mr-2 mb-2 px-2"
            type="text"
            placeholder="Enter email or display name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="border border-blue-200 rounded-md mr-2 px-2"
            onClick={handleFindUser}
          >
            Find User
          </button>
          <button
            className="border border-blue-200 rounded-md mr-2 px-2"
            onClick={handleShowAll}
          >
            Show All Users
          </button>
        </div>
        <UsersTable users={searchResults.length > 0 ? searchResults : users} />
      </div>
    </div>
  );
};

export default AdminPage;
