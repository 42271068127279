import React, { useState, useEffect } from "react";
import APIRoutes from "../api/apiRoutes";
import { formatDate } from "../utils/dateFormatter";
import PayPal from "./paypal/PayPal";
import SubscriptionDetails from "./SubscriptionDetails";
import SubscriptionCancel from "./modals/SubscriptionCancel";
import LoadingSpinner from "../components/LoadingSpinner";
import TypingEffect from "../components/TypingEffect";
const SubscriptionList = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("testreason");
  const [subscriptionIdToCancel, setSubscriptionIdToCancel] = useState(null);
  const [cancelProcess, setProcessCancel] = useState(false);
  const fetchSubscriptionInfo = async () => {
    try {
      const response = await fetch(APIRoutes.getSubscriptionInfo, {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      if (response.ok) {
        const subscriptionsData = await response.json();
        setSubscriptions(subscriptionsData.subscriptions || []);
        // console.log(JSON.stringify(subscriptionsData));
      } else {
        console.error("Failed to fetch subscription information");
      }
    } catch (error) {
      console.error("Error fetching subscription information:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptionInfo();
  }, []);

  const handleCancelSubscriptionClick = (subscriptionId) => {
    setSubscriptionIdToCancel(subscriptionId);
    setShowCancelForm(true);
  };

  const handleCancelFormSubmit = async () => {
    try {
      const response = await fetch(
        `${APIRoutes.cancelSubscription}`.replace(
          ":id",
          subscriptionIdToCancel
        ),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            cancellationReason: cancellationReason,
          }),
        }
      );
    } catch (err) {
      console.error(
        "Failed to cancel subscription. Please use your PayPal cabinet."
      );
    }
    setShowCancelForm(false);
    setCancellationReason("testReason");
    setProcessCancel(true);
  };

  return (
    <div className="items-center w-full">
      <h2 className="text-2xl font-bold text-orange-500 text-center">
        Subscription Information
      </h2>
      {cancelProcess && (
        <div className="">
          <LoadingSpinner></LoadingSpinner>
          <TypingEffect
            message="Subscription will be completely cancelled within 2 minutes"
            speed={100}
            loop={true}
          />
        </div>
      )}
      {subscriptions.length === 0 ? (
        <div className="mt-4 relative items-center border border-orange-300 rounded-md p-4">
          <div className="text-center">
            <div>
              You have no active subscriptions. In order to use our application,
              you need one.
            </div>
            <div>
              You will get 7 trial days to test it and use every functionality
              with no restrictions at all.
            </div>
            <div>You can cancel your subscription anytime. </div>
            <div>After the trial period ends, it is 9.99 USD monthly. </div>
          </div>
          <PayPal />
        </div>
      ) : (
        !subscriptions.some(
          (subscription) => subscription.status === "ACTIVE"
        ) && (
          <div className="mt-4 relative items-center border border-orange-300 rounded-md p-4">
            <div className="text-center">
              <div>
                You have no active subscriptions. In order to use our
                application, you need one.
              </div>
              <div>You can cancel your subscription anytime. </div>
              <div>It is 9.99 USD monthly. </div>
            </div>
            <PayPal></PayPal>
          </div>
        )
      )}

      {subscriptions
        .sort((a, b) => new Date(b.create_time) - new Date(a.create_time))
        .map((subscription) => (
          <div
            key={subscription._id}
            className="flex items-center border border-orange-300 rounded-xl w-full px-10 py-2 mt-4"
          >
            <div className="flex-grow">
              {" "}
              <SubscriptionDetails subscription={subscription} />
            </div>
            {subscription.status === "ACTIVE" && (
              <button
                onClick={() => handleCancelSubscriptionClick(subscription._id)}
                className="border border-orange-400  hover:bg-red-600 hover:text-white active:bg-red-700 text-orange-400 py-2 px-4 rounded"
              >
                Cancel Subscription
              </button>
            )}

            {showCancelForm && (
              <SubscriptionCancel
                text=""
                onCancel={() => setShowCancelForm(false)}
                onConfirm={() => handleCancelFormSubmit(subscription._id)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default SubscriptionList;
