import { Link } from "react-router-dom";
import { useState } from "react";
import { formatDateForChatInfo } from "../utils/dateFormatter";
import APIRoutes from "../api/apiRoutes";
import ChatInfoComments from "./ChatInfoComments";

const ChatInfo = ({ chat }) => {
  const [chatStatus, setChatStatus] = useState(chat.status);

  const updateChatStatus = async (newStatus) => {
    try {
      // Make a request to your server to update the chat status
      const response = await fetch(
        `${APIRoutes.adminChangeChatStatus}/${chat._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (response.ok) {
        setChatStatus(newStatus);
      } else {
        console.error("Failed to update chat status");
      }
    } catch (error) {
      console.error("Error updating chat status:", error);
    }
  };

  // Method to mark the chat as done
  const markChatAsDone = () => {
    updateChatStatus("done");
  };

  // Method to move the chat to active
  const moveChatToActive = () => {
    updateChatStatus("in_progress");
  };

  return (
    <div
      className={`border ${chatStatus === "new" ? "bg-orange-400" : ""} ${
        chatStatus === "in_progress" ? "bg-blue-400" : ""
      } ${chatStatus === "done" ? "border-green-400" : ""} rounded p-4 my-1 `}
    >
      <div className="grid grid-cols-2 gap-4 bg-white p-2 rounded ">
        <li className="" key={chat._id}>
          {/* Left Section */}
          <div className="">
            <Link to={`/chat/${chat._id}`}>
              <h2 className="font-semibold">{chat.participants[0]}</h2>
              <p>Status: {chatStatus}</p>
              {chat.lastMessage != null && (
                <div>
                  <span>
                    {formatDateForChatInfo(chat.lastMessage.timestamp)}:{" "}
                  </span>
                  <span className="font-semibold">
                    {" "}
                    {chat.lastMessage.content}
                  </span>
                  <br />
                </div>
              )}
            </Link>
            {chatStatus === "in_progress" && (
              <button
                onClick={markChatAsDone}
                className="border border-blue-200 rounded-md mr-2 mt-2 px-2"
              >
                Mark as Done
              </button>
            )}
            {chatStatus === "done" && (
              <button
                onClick={moveChatToActive}
                className="border border-blue-200 rounded-md mr-2 mt-2 px-2"
              >
                Move to Active
              </button>
            )}
          </div>
        </li>

        {/* Right Section */}
        <div className=" max-h-60">
          <ChatInfoComments chatId={chat._id} maxHeight={"160px"} />
        </div>
      </div>
    </div>
  );
};

export default ChatInfo;
