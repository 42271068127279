// DeleteConfirmationModal.js

import React from "react";

const DeleteModal = ({ text, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md">
        <p className="text-lg font-semibold mb-4">
          {text} This action is not reversible.
        </p>
        <div className="flex justify-end">
          <button
            className="border-none bg-gray-300 hover:bg-gray-400 py-2 px-4 mr-2 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="border-none bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
            onClick={onConfirm}
          >
            Confirm Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
