import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MyTestplansPage from "./pages/MyTestplansPage";
import TestplanDetailPage from "./pages/TestplanDetailPage";
import "./styles.css";
import LoginPage from "./pages/LoginPage";
import Header from "./components/Header";
import AboutPage from "./pages/AboutPage";
import LandingPage from "./pages/LandingPage";
import BillingPage from "./pages/BillingPage";
import AdminPage from "./pages/AdminPage";
import SupportPage from "./pages/SupportPage";
import ChatAdminPage from "./pages/ChatAdminPage";
import { SocketProvider } from "./context/SocketContext";
import { UserDataProvider } from "./context/UserDataContext";
const App = () => {
  const [isProfilePopupOpen, setProfilePopupOpen] = useState(false);

  const handleProfileClick = () => {
    setProfilePopupOpen(true);
  };

  const handleCloseProfilePopup = () => {
    setProfilePopupOpen(false);
  };
  useEffect(() => {
    document.title = "QA-Helper App"; // Set your desired title here
  }, []);

  return (
    <UserDataProvider>
      <SocketProvider>
        <div className="min-w-[1310px]">
          <Header onProfileClick={handleProfileClick} />
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/"
              element={
                <LandingPage
                  isProfilePopupOpen={isProfilePopupOpen}
                  onCloseProfile={handleCloseProfilePopup}
                />
              }
            />
            <Route
              path="/testplans"
              element={
                <MyTestplansPage
                  isProfilePopupOpen={isProfilePopupOpen}
                  onCloseProfile={handleCloseProfilePopup}
                />
              }
            />
            <Route
              path="/testplans/:id"
              element={
                <TestplanDetailPage
                  isProfilePopupOpen={isProfilePopupOpen}
                  onCloseProfile={handleCloseProfilePopup}
                />
              }
            />
            <Route
              path="/about"
              element={
                <AboutPage
                  isProfilePopupOpen={isProfilePopupOpen}
                  onCloseProfile={handleCloseProfilePopup}
                />
              }
            />
            <Route
              path="/billing"
              element={
                <BillingPage
                  isProfilePopupOpen={isProfilePopupOpen}
                  onCloseProfile={handleCloseProfilePopup}
                />
              }
            />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/chat/:chatId" element={<ChatAdminPage />} />

            <Route
              path="/support"
              element={
                <SupportPage
                  isProfilePopupOpen={isProfilePopupOpen}
                  onCloseProfile={handleCloseProfilePopup}
                />
              }
            />
            {/* <Route path="/" element={<App />} /> */}
          </Routes>
        </div>
      </SocketProvider>
    </UserDataProvider>
  );
};

export default App;
