import React from "react";
import Login from "../components/login/Login";

function LoginPage() {
  return (
    <div className="relative">
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-orange-500 from-10% via-orange-300 to-orange-500 to-95% opacity-50"></div>

      {/* Container */}
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-orange-500 from-10% via-orange-300 to-orange-500 to-95%">
        <div className="bg-white p-8 rounded-md shadow-md w-96 relative z-10">
          {/* QA-Helper logo */}
          <img alt="long-blue-logo" src="/helper-logos/long-blue.png"></img>
          {/* Header */}
          <h1 className="text-2xl font-bold mb-6">Welcome To QA-Helper App!</h1>
          <Login />
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
