import React, { useState, useEffect } from "react";
import Login from "./login/Login";
import APIRoutes from "../api/apiRoutes";
import EditableInfo from "./EditableInfo";
import { formatDate } from "../utils/dateFormatter";
import { useUser } from "../context/UserDataContext";

const ProfilePopup = ({ onClose }) => {
  const { userData, setUserData, fetchUserData } = useUser();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state

  // Function to handle changes in the editable fields
  const handleFieldChange = async (field, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [field]: value,
    }));
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      const accessToken = localStorage.getItem("accessToken");
      setIsLoggedIn(!!accessToken);

      // Fetch user data if logged in
      if (accessToken) {
        fetchUserData(accessToken);
      }
    };

    checkLoginStatus();
  }, []); // Empty dependency array ensures this runs only on mount

  // Function to save the updated user information
  const handleSaveField = async (field, value) => {
    // console.log("START");
    // console.log(JSON.stringify({ [field]: value }));
    try {
      // Send a request to update a specific user information in the database
      const response = await fetch(APIRoutes.updateUserInfo, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({ [field]: value }), // Send only the specific field and value
      });

      if (response.ok) {
        // console.log(`${field} updated successfully`);
      } else {
        console.error(`Failed to update ${field}`);
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };
  const handleLogout = async () => {
    try {
      // Send a POST request to the server to logout
      fetch(APIRoutes.logout, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }).then((res) => {
        if (res.ok) {
          // console.log("Logout successful");
          localStorage.removeItem("accessToken"); // Remove accessToken from localStorage
          setIsLoggedIn(false); // Set isLoggedIn to false
          window.location.href = "/"; // Redirect to the landing page
        } else {
          console.error("Logout failed");
        }
      });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className="col-span-1 col-start-3 relative p-8 bg-white rounded-lg shadow-md h-fit self-start flex flex-col items-center justify-center min-w-fit">
      <img
        className="w-1/5"
        alt="long-blue-logo"
        src="/helper-logos/square-blue.png"
      ></img>
      {isLoggedIn ? (
        <div className="w-full mt-4">
          <div className="border border-orange-300 rounded p-4">
            <div className="grid grid-flow-col">
              <h2 className="text-xl font-bold text-orange-500">User Info</h2>
              {userData.image && (
                <img
                  alt="profileimage"
                  src={userData.image} // Assuming userData.photo contains the photo URL
                  className="w-12 h-12 rounded-full mr-4"
                />
              )}
            </div>

            <EditableInfo
              header="Full Name"
              userData={userData.displayName}
              handleFieldChange={(value) =>
                handleFieldChange("displayName", value)
              }
              onSave={(value) => handleSaveField("displayName", value)}
            />
            <EditableInfo
              header="Email"
              userData={userData.email}
              handleFieldChange={null}
            />

            <EditableInfo
              header="Company Name"
              userData={userData.companyName}
              handleFieldChange={(value) =>
                handleFieldChange("companyName", value)
              }
              onSave={(value) => handleSaveField("companyName", value)}
            />

            <div className=" text-orange-500 mt-4">
              <div>
                <h2 className="text-xl font-bold text-orange-500">
                  Subscription
                </h2>
              </div>
              <div className="text-orange-500 font-semibold items-center w-full">
                {!userData.activeSubscription ? (
                  <div className="font-normal">
                    There is no active subscription so far. Go to Billing in
                    order to subscribe. Or contact Support if there is a
                    mistake.
                  </div>
                ) : (
                  <div>
                    <div>
                      <span className="">
                        {userData.activeSubscription.plan.name}:
                      </span>
                      <span className="text-gray-600 font-normal items-center ml-1">
                        {userData.activeSubscription.plan.description}
                        {userData.isTrial && " (Trial Period)"}
                      </span>
                    </div>
                    <div>
                      <span className="">Start Date: </span>
                      <span className="text-gray-600 font-normal items-center ml-1">
                        {formatDate(userData.activeSubscription.start_time)}
                      </span>
                    </div>
                    <div>
                      <span className="">Next Billing: </span>
                      <span className="text-gray-600 font-normal items-center ml-1">
                        {formatDate(
                          userData.activeSubscription.billing_info
                            .next_billing_time
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Add links to billing page and support */}
          <div className="mt-4 flex place-items-stretch text-orange-400 min-w-fit">
            <a href="/billing" className="">
              Billing
            </a>
            <span className="mx-2">|</span>
            <a href="/support">Support</a>
            <span className="mx-2">|</span>
            {/* <a href="/review">Review</a>
            <span className="mx-2">|</span> */}
            {userData.role === "admin" && <a href="/admin">Admin</a>}
            {userData.role === "admin" && <span className="mx-2">|</span>}

            <button className={` border-none p-0`} onClick={handleLogout}>
              Logout
            </button>
          </div>
          <button
            className="mt-4 border-orange-300 rounded-lg"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      ) : (
        <div className="w-full">
          <Login />
        </div>
      )}
    </div>
  );
};

export default ProfilePopup;
