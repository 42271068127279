const isProduction = process.env.NODE_ENV === "production";

const APIHost = isProduction
  ? "https://qa-helper-app-8c7440cb4e45.herokuapp.com"
  : "http://localhost:5001";

const APIRoutes = {
  socket: `${APIHost}`,

  getTestplans: `${APIHost}/testplans`,
  getTestplan: `${APIHost}/testplans/:id`,
  initTestplan: `${APIHost}/ai/testplan-init`,
  deleteTestplan: `${APIHost}/testplans`,

  generateChapter: `${APIHost}/ai/testplans/:id/generate-chapter/:index`,
  editChapter: `${APIHost}/ai/testplans/:id/edit-chapter/:index`,
  saveChapter: `${APIHost}/testplans/:id/chapter/:index`,
  getChapter: `${APIHost}/testplans/:id/chapter/:index`,

  saveTestplanDraft: `${APIHost}/testplans/:id/update-draft`,
  updateTestplanDescription: `${APIHost}/testplans/:id/update-description`,

  register: `${APIHost}/auth/google`,
  googleUserCheck: `${APIHost}/auth/login-google`,
  logout: `${APIHost}/auth/logout`,
  getUser: `${APIHost}/auth/user-info`,
  getUnreadMessages: `${APIHost}/auth/unread-messages`,
  updateUserInfo: `${APIHost}/auth/update-user-info`,

  getSubscriptionInfo: `${APIHost}/subscriptions/info`,
  upgradeSubscription: `${APIHost}/subscriptions/upgrade`,
  cancelSubscription: `${APIHost}/subscriptions/cancel/:id`,
  renewSubscription: `${APIHost}/subscriptions/renew`,

  supportGetChat: `${APIHost}/support/getAllMessages`,
  supportReadChat: `${APIHost}/support/read-chat`,

  adminGetUsers: `${APIHost}/admin/user-info-unsafe`,
  adminGetStatistics: `${APIHost}/admin/statistics`,
  adminChangeRole: `${APIHost}/admin/change-user-role`,
  adminUserDelete: `${APIHost}/admin/user-delete`,
  adminGetMessages: `${APIHost}/admin/admin-chats/all-messages`,
  adminReplyToMessage: `${APIHost}/admin/reply-to-message`,
  adminGetChats: `${APIHost}/admin/admin-chats`,
  adminChangeChatStatus: `${APIHost}/admin/admin-chats/change-status`,
  adminAddComment: `${APIHost}/admin/admin-chats/add-comment`,
  adminGetComments: `${APIHost}/admin/admin-chats/all-comments`,
  adminSupportChatStatuses: `${APIHost}/admin/support-chat-statuses`,

  paypalCreateSubscription: `${APIHost}/paypal/api/paypal/create-subscription`,
  paypalCreateOrder: `${APIHost}/paypal/api/orders`,
  paypalCaptureOrder: `${APIHost}/paypal/api/orders/:id/capture`,
};

export default APIRoutes;
