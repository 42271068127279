import React from "react";
import { formatDate } from "../utils/dateFormatter";
import SubscriptionCancel from "./modals/SubscriptionCancel";

const SubscriptionDetails = ({ subscription }) => {
  const currentCycle = subscription.billing_info.cycle_executions.find(
    (cycle) => cycle.cycles_completed > 0 && cycle.tenure_type === "REGULAR"
  );
  // Determine the cycle type
  let cycleInfo;
  let colorStatus = "text-green-500";
  if (currentCycle) {
    cycleInfo =
      "Autorenews on " +
      formatDate(subscription.billing_info.next_billing_time);
  } else {
    cycleInfo =
      "Trial period until " +
      formatDate(subscription.billing_info.next_billing_time);
  }

  if (subscription.status === "CANCELLED") {
    cycleInfo = "Cancelled on " + formatDate(subscription.status_update_time);
    colorStatus = "text-red-500";
  } else if (subscription.status === "APPROVAL_PENDING") {
    cycleInfo = (
      <span>
        You can manage your subscription via{" "}
        <a
          href="https://www.paypal.com/signin?country.x=US&locale.x=en_US"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-700 hover:text-orange-400"
        >
          PayPal Dashboard
        </a>
        . Subscription ID is {subscription.id}.
      </span>
    );
    colorStatus = "text-yellow-500";
  }

  return (
    <div className="flex items-center">
      <div className="flex-grow">
        <p className="font-semibold">
          {subscription.plan.name} - {subscription.plan.description}
        </p>
        <p>
          Status:{" "}
          <span className={`${colorStatus}`}>{subscription.status}</span>
        </p>
        <p>Start: {formatDate(subscription.start_time)}</p>
        <p>{cycleInfo}</p>{" "}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
