import React, { useState, useEffect, useRef } from "react";
import { useSocket } from "../context/SocketContext";
import APIRoutes from "../api/apiRoutes";
import {
  getDate,
  formatDateToDateOnly,
  formatDateToTimeOnly,
} from "../utils/dateFormatter";
import { useUser } from "../context/UserDataContext";
import LoadingSpinner from "../components/LoadingSpinner";
import TypingEffect from "../components/TypingEffect";

const ChatUser = () => {
  const { socket } = useSocket();
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [message, setMessage] = useState("");
  const userToken = localStorage.getItem("accessToken");
  const { setUserUnreadMessages } = useUser();
  // Sort messages based on dateTime
  const allMessages = [...receivedMessages, ...sentMessages].sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
  );
  const [isLoading, setIsLoading] = useState(true);

  // Function to fetch previous chat messages
  const fetchPreviousMessages = async () => {
    try {
      const response = await fetch(APIRoutes.supportGetChat, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken,
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Convert timestamps to the user's local time for display
        const allMessages = data.map((msg) => {
          return {
            ...msg,
          };
        });

        // Update state with all messages
        setReceivedMessages(allMessages);
        setIsLoading(false);
      } else {
        console.error("Failed to fetch chat messages");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch previous messages when the component mounts
    fetchPreviousMessages();
  }, []);

  // Create a ref for the message container
  const messageContainerRef = useRef(null);

  // Function to scroll to the bottom of the message container
  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };
  // Use effect to scroll to the bottom whenever new messages are added
  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  // Later in your component, you can listen for incoming messages

  useEffect(() => {
    if (socket) {
      socket.on("support_message", ({ adminName, message, dateTime }) => {
        // console.log(`Received message from ${adminName}: ${message}`);
        setReceivedMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: adminName,
            content: message,
            dateTime,
          },
        ]);
      });

      return () => {
        socket.off("support_message");
      };
    }
  }, [socket]);

  const handleSendMessage = () => {
    const token = socket?.io?.opts?.auth?.token || "";
    const dateTime = new Date().toISOString();

    socket.emit("user_message", { token, message });
    setSentMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: "You",
        content: message,
        dateTime,
      },
    ]);
    setMessage(""); // Clear the input field after sending the message
    setUserUnreadMessages(false); //remove notification
    const textarea = document.getElementById("chatTextarea");
    if (textarea) {
      textarea.style.height = "auto"; //adjust height back to 1 line
    }
  };
  const handleKeyPress = (e) => {
    // Check if "Enter" key is pressed without holding "Shift"
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior (e.g., submitting a form)
      handleSendMessage();
    }
  };
  const maxLines = 8;

  const handleInputHeight = (e) => {
    // Set the textarea height based on its scrollHeight
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";

    // Limit the number of visible lines
    const lines = e.target.value.split("\n").length;
    if (lines > maxLines) {
      e.target.style.overflowY = "auto"; // Enable vertical scrollbar
      e.target.style.height = `${maxLines * 1.5}em`; // Adjust the height to show the desired number of lines
    } else {
      e.target.style.overflowY = "hidden"; // Disable vertical scrollbar
    }
  };
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner></LoadingSpinner>
        <TypingEffect
          message="Loading previous messages..."
          speed={100}
          loop={true}
        />
      </div>
    );
  }
  return (
    <div className="container mx-auto p-4">
      <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl">
        <h2 className="text-2xl font-bold mb-4">Chat with Support team</h2>

        <div
          className="message-container"
          ref={messageContainerRef} // Attach the ref to the message container
          style={{ maxHeight: "400px", overflowY: "auto" }} // Set a max height and enable vertical scrolling
        >
          {allMessages.map((msg, index) => (
            <div key={index}>
              {/* Check if the current message's date is different from the previous one */}
              {index === 0 ||
              getDate(allMessages[index - 1].dateTime) !==
                getDate(msg.dateTime) ? (
                <p className="text-center text-sm mb-2">
                  {formatDateToDateOnly(msg.dateTime)}
                </p>
              ) : null}

              <p
                className={`text-xs ${
                  msg.sender === "You" ? "text-right" : ""
                }`}
              >
                <span className="font-bold">{msg.sender}:</span>{" "}
                {formatDateToTimeOnly(msg.dateTime)}
              </p>
              <div
                className={`${
                  msg.sender === "You" ? "sent-message" : "received-message"
                } bg-gray-300 p-2 rounded-lg mb-2 max-w-fit max-w-2/3 ${
                  msg.sender === "You"
                    ? "text-right bg-orange-300 ml-auto w-fit "
                    : "mr-auto"
                }`}
                style={{ whiteSpace: "pre-line" }}
              >
                {msg.content}
              </div>
            </div>
          ))}
        </div>

        <div
          className="flex flex-col items-end mt-4"
          style={{ marginBottom: "16px" }} // Add margin to separate input and button
        >
          <textarea
            id="chatTextarea"
            rows="1" // Set the initial number of visible text lines
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              handleInputHeight(e);
            }}
            onKeyPress={handleKeyPress} // Handle key press events
            className="border p-2 w-full mb-2 resize-none" // Disable textarea resizing
          />
          <button
            onClick={() => {
              handleSendMessage();
              scrollToBottom(); // Scroll to the bottom after sending a message
            }}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatUser;
