import React, { useState } from "react";
import generateTestPlan from "../data/testPlan";
import APIRoutes from "../api/apiRoutes";
import LoadingSpinner from "./LoadingSpinner";
import TypingEffect from "./TypingEffect";
import InfoIcon from "./modals/InfoIcon";
const NewTestPlanForm = ({ onCreateTestPlan }) => {
  const [projectName, setProjectName] = useState("");
  const [testPlanType, setTestingType] = useState("functional");
  const [projectType, setProjectType] = useState("internal");
  const [externalCompanyName, setExternalCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [description, setDescription] = useState(""); // Add description state
  const accessToken = localStorage.getItem("accessToken");
  const [borderColorProjectName, setBorderColorProjectName] = useState(
    "w-full border-2 border-orange-400/50 py-2 focus:outline-none rounded"
  );
  const [borderColorDescription, setBorderColorDescription] = useState(
    "w-full border-2 border-orange-400/50 py-2 focus:outline-none rounded"
  );

  const handleCreateTestPlan = async () => {
    if (projectName.trim() === "") {
      console.log("Project name is required.");
      setBorderColorProjectName(
        "w-full border-2 border-red-400 py-2 focus:outline-none rounded"
      );
      return;
    }
    if (description.trim() === "") {
      setBorderColorDescription(
        "w-full border-2 border-red-400 py-2 focus:outline-none rounded"
      );
      return;
    }

    setIsLoading(true); // Set isLoading to true while waiting for the response

    // Pass the additional props to the generateTestPlan function
    const newTestPlan = generateTestPlan(
      projectName,
      testPlanType,
      projectType,
      externalCompanyName,
      description
    );

    try {
      // Send the new test plan to the server to process
      const response = await fetch(APIRoutes.initTestplan, {
        method: "POST",
        headers: {
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTestPlan),
      });

      if (response.ok) {
        onCreateTestPlan({
          ...newTestPlan,
        });
        window.location.reload();
      } else {
        console.error("Failed to create a test plan.");
      }
    } catch (error) {
      console.error("Error creating a test plan:", error);
    } finally {
      setIsLoading(false); // Set isLoading to false after receiving the response
    }
  };

  return (
    <div className="w-full min-w-md mx-auto bg-white p-8 border-orange-400 rounded">
      <span className="text-2xl font-bold mb-4">Create a New Test Plan</span>
      <span className="ml-2">
        <InfoIcon
          infoText={
            "This form purpose is to give AI context about your project. Kindly provide information. After submitting a form testplan description will be generated. You will be able to edit it. This description later will help to provide AI model with context of your project. Let's start!"
          }
        />
      </span>
      <div className="my-4">
        <label
          htmlFor="projectName"
          className="block text-sm font-semibold text-gray-600"
        >
          Project Name:
        </label>
        <input
          type="text"
          id="projectName"
          style={{ paddingInlineEnd: "15px", paddingInlineStart: "15px" }}
          className={borderColorProjectName}
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="description"
          className="block text-sm font-semibold text-gray-600"
        >
          What is SUT About:
        </label>
        <textarea
          id="description"
          style={{ paddingInlineEnd: "15px", paddingInlineStart: "15px" }}
          className={borderColorDescription}
          placeholder="Describe the main purpose of the app/system under test"
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="testingType"
          className="block text-sm font-semibold text-gray-600 rounded"
        >
          Testing Type:
        </label>
        <select
          id="testingType"
          className="w-full border-2 border-orange-400/50 py-2 focus:outline-none rounded"
          value={testPlanType}
          onChange={(e) => setTestingType(e.target.value)}
        >
          <option value="general">
            Both (Functional and Non-Functional - not recommended)
          </option>
          <option value="functional">Functional</option>
          <option value="non-functional">Non-Functional</option>
        </select>
      </div>

      <div className="mb-4">
        <label
          htmlFor="projectType"
          className="block text-sm font-semibold text-gray-600"
        >
          Project Type:
        </label>
        <select
          id="projectType"
          className="w-full border-2 border-orange-400/50 py-2 focus:outline-none rounded"
          value={projectType}
          onChange={(e) => setProjectType(e.target.value)}
        >
          <option value="internal">Internal</option>
          <option value="external">External</option>
        </select>
      </div>

      {projectType === "external" && (
        <div className="mb-4">
          <label
            htmlFor="externalCompanyName"
            className="block text-sm font-semibold text-gray-600"
          >
            External Company Name:
          </label>
          <input
            type="text"
            id="externalCompanyName"
            className="w-full border-2 border-orange-400/50 py-2 focus:outline-none rounded"
            value={externalCompanyName}
            onChange={(e) => setExternalCompanyName(e.target.value)}
          />
        </div>
      )}

      {isLoading ? (
        <div className="mt-2">
          <LoadingSpinner></LoadingSpinner>
          <TypingEffect
            message="New test plan template is being generated..."
            speed={100}
            loop={true}
          />
        </div>
      ) : (
        <button
          className="w-full border-white bg-orange-500 hover:bg-orange-400 active:bg-orange-600 text-white py-2 px-4 rounded"
          onClick={handleCreateTestPlan}
        >
          Start a new test plan
        </button>
      )}
    </div>
  );
};

export default NewTestPlanForm;
