// DeleteConfirmationModal.js

import React from "react";

const SubscriptionCancel = ({ text, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md">
        <p className="text-lg font-semibold mb-4">
          You are about to cancel your subscription.
        </p>
        <p>Before you proceed:</p>
        <br></br>
        <p>
          Please be aware that you will not be able to use any features of the
          app without active subscription.
        </p>
        <p>
          Even if your subscription has not come to an end, cancelling it means
          removing active subscription.
        </p>
        <p>
          You will not be able to subscribe for the trial again with the same
          account.
        </p>
        <br></br>

        <div className="flex justify-end">
          <button
            className="border border-white bg-green-300 hover:bg-white hover:text-green-400 hover:border-green-400 py-2 px-4 mr-2 rounded"
            onClick={onCancel}
          >
            Keep Subscription
          </button>
          <button
            className="border-none hover:bg-red-600 text-orange-400 py-2 px-4 rounded"
            onClick={onConfirm}
          >
            Cancel Subscription
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCancel;
