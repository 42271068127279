// Import the DetailRow component
import DetailRow from "./DetailRow";
import APIRoutes from "../api/apiRoutes";
import React, { useState } from "react";

const TestPlanInfo = ({ testPlan, isLink, isEditable }) => {
  const [editedDescription, setEditedDescription] = useState(
    testPlan.description
  );

  const containerStyles = isLink
    ? "border border-orange-500/50 bg-white text-black hover:bg-orange-200 active:bg-orange-400 active:border-orange-500 py-2 px-4 rounded w-full"
    : "border border-orange-500/50 bg-white text-black py-2 px-4 rounded w-full";

  // Function to save the updated testplan description
  const handleSaveDescription = async (value) => {
    try {
      // Send a request to update the testplan description in the database
      const response = await fetch(
        `${APIRoutes.updateTestplanDescription.replace(":id", testPlan._id)}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({ description: value }), // Send the updated description
        }
      );

      if (response.ok) {
        const data = await response.json();
        setEditedDescription(data.description);
        // console.log("Description updated successfully");
      } else {
        console.error("Failed to update description");
      }
    } catch (error) {
      console.error("Error updating description:", error);
    }
  };

  return (
    <div className={containerStyles}>
      <DetailRow
        label="Project Name"
        value={testPlan.projectName}
        isEditable={false}
      />
      <DetailRow
        label="Project Type"
        value={testPlan.projectType}
        isEditable={false}
      />
      {testPlan.projectType === "external" && (
        <DetailRow
          label="External Company Name"
          value={testPlan.externalCompanyName}
          isEditable={false}
        />
      )}
      <DetailRow
        label="Created on"
        value={testPlan.creationDate}
        isEditable={false}
      />
      {isEditable ? (
        <div className="grid">
          <DetailRow
            label="Description"
            value={editedDescription}
            isEditable={true}
            onChange={(e) => setEditedDescription(e.target.value)}
            onSave={(value) => handleSaveDescription(value)}
          />
        </div>
      ) : (
        <DetailRow
          label="Description"
          value={testPlan.description}
          isEditable={false}
        />
      )}
    </div>
  );
};

export default TestPlanInfo;
