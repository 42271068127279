import React, { useState, useEffect } from "react";
import Question from "./Question";
import APIRoutes from "../api/apiRoutes";
import TextEditor from "./TextEditor";
import TypingEffect from "./TypingEffect";
import LoadingSpinner from "./LoadingSpinner";
import InfoIcon from "./modals/InfoIcon";
import "../css/innerHTML.css";

const Chapter = ({ chapter, testPlanId, chapterIndex }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [chapterContent, setChapterContent] = useState("");
  const [responses, setResponses] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [isWorkingOnChapter, setIsWorkingOnChapter] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const [chapterIsDone, setChapterIsDone] = useState(
    localStorage.getItem(`chapterIsDone_${testPlanId}_${chapterIndex}`) ===
      "true"
  );
  const [chapterBorderStyle, setChapterBorderStyle] = useState(
    localStorage.getItem(`chapterBorderStyle_${testPlanId}_${chapterIndex}`) ||
      "border border-orange-500/50 bg-white text-black py-2 px-4 rounded my-2"
  );

  useEffect(() => {
    localStorage.setItem(
      `chapterIsDone_${testPlanId}_${chapterIndex}`,
      chapterIsDone
    );
    localStorage.setItem(
      `chapterBorderStyle_${testPlanId}_${chapterIndex}`,
      chapterBorderStyle
    );
  }, [testPlanId, chapterIndex, chapterIsDone, chapterBorderStyle]);

  const handleWorkOnChapter = async () => {
    // When "Work on Chapter" is clicked, check the content of the chapter
    // by sending a GET request.
    try {
      const response = await fetch(
        `${APIRoutes.getChapter
          .replace(":id", testPlanId)
          .replace(":index", chapterIndex)}`,
        {
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data && data.content) {
          // console.log(JSON.stringify(data));
          setChapterContent(data.content);
        }
      }
      setIsWorkingOnChapter(true);
    } catch (error) {
      console.error("Error fetching chapter data: ", error);
    }
  };

  const hideChapter = async () => {
    setIsWorkingOnChapter(false);
  };

  const changeChapterStatus = async () => {
    if (chapterIsDone) {
      setChapterBorderStyle(
        "border border-orange-500/50 bg-white text-black py-2 px-4 rounded my-2"
      );
      setChapterIsDone(false);
    } else {
      setChapterBorderStyle(
        "border border-green-500 bg-white text-black py-2 px-4 rounded my-2"
      );
      setChapterIsDone(true);
      setIsWorkingOnChapter(false);
    }
  };

  const handleGenerate = async () => {
    setIsGenerating(true); // Set the generating status to true when generation starts

    // Prepare the data to send to your API route for generating chapter content
    const requestData = {
      testPlanId,
      chapterIndex,
      responses,
    };

    try {
      const response = await fetch(
        // Use the fetch API to make a POST request
        `${APIRoutes.generateChapter
          .replace(":id", testPlanId)
          .replace(":index", chapterIndex)}`,
        {
          method: "POST",
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        // console.log(JSON.stringify(response));
        const data = await response.json();
        if (data && data.content) {
          setChapterContent(data.content);
        } else {
          console.error("Failed to generate chapter content");
        }
      } else {
        console.error("Failed to generate chapter content");
      }
      setIsGenerating(false);
    } catch (error) {
      console.error("Error generating chapter content:", error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (content) => {
    // Implement logic to save the edited content
    setIsEditing(false);

    // Prepare the data to send for saving the chapter content
    const requestData = {
      testPlanId,
      chapterIndex,
      content, // The edited chapter content
    };

    try {
      const response = await fetch(
        `${APIRoutes.saveChapter
          .replace(":id", testPlanId)
          .replace(":index", chapterIndex)}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const editedChapterContent = await response.json();
        if (editedChapterContent) {
          setChapterContent(editedChapterContent);
        } else {
          console.error(
            "Failed to edit chapter content. Edited chapter content was: " +
              editedChapterContent
          );
        }
      } else {
        console.error("Failed to save chapter content");
      }
    } catch (error) {
      console.error("Error saving chapter content:", error);
    }
  };

  const handleRequestChange = async () => {
    setIsGenerating(true);
    // Implement logic to submit content to AI
    setIsEditing(false);
    const requestData = {
      testPlanId,
      chapterIndex,
      content: chapterContent, // The chapter content with comments or changes
    };

    try {
      const response = await fetch(
        `${APIRoutes.editChapter
          .replace(":id", testPlanId)
          .replace(":index", chapterIndex)}`,
        {
          method: "POST",
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data && data.content) {
          // Update the chapterContent with the AI-generated content
          setChapterContent(data.content);
        } else {
          console.error("Failed to generate chapter content");
        }
      } else {
        console.error("Failed to generate chapter content");
      }
      setIsGenerating(false);
    } catch (error) {
      console.error("Error requesting changes:", error);
    }
  };

  const handleResponse = (question, answer) => {
    // Update the responses state with the user's answer to a question
    setResponses({
      ...responses,
      [question]: answer,
    });
  };

  const handleTextEditorChange = (newContent) => {
    setChapterContent(newContent);
  };

  return (
    <div className={chapterBorderStyle}>
      <h4 className="text-2xl font-bold text-gray-800">
        {chapterIndex + 1}. {chapter.name}
      </h4>

      {/* Display questions or content if working on the chapter */}
      {isWorkingOnChapter ? (
        <div>
          {isGenerating ? (
            <div className="mt-2">
              <LoadingSpinner></LoadingSpinner>
              <TypingEffect
                message="The chapter is being generated..."
                speed={100}
                loop={true}
              />
            </div>
          ) : (
            <div>
              {!chapterContent ? (
                <div>
                  <div>{/* If no content - display questions */}</div>
                  {chapter.infoFromUser.map((question, index) => (
                    <Question
                      key={index}
                      question={question.question}
                      answer={responses[question.question] || ""}
                      handleResponse={(answer) =>
                        handleResponse(question.question, answer)
                      }
                    />
                  ))}
                  <div>
                    <p className="mt-3">
                      Before pressing Generate Chapter, make sure to provide
                      answers to the questions above.
                    </p>
                    <button
                      className="border border-green-500 bg-white text-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 active:border-green-600 py-2 px-4 rounded mt-4"
                      onClick={handleGenerate}
                    >
                      Generate Chapter
                    </button>
                    <button
                      className="mx-1 border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
                      onClick={hideChapter}
                    >
                      Answer Later
                    </button>
                    <span className="ml-2">
                      <InfoIcon
                        infoText={
                          "After You provide answers to the questions above, press Generate Chapter and AI model will provide you the sampling of the chapter which later can be modified manually or with your specific prompt to AI."
                        }
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    {/* If there is content - display content, not questions */}
                  </div>
                  {isEditing ? (
                    <div>
                      <TextEditor
                        initialText={chapterContent}
                        onEditorContentChange={handleTextEditorChange}
                        onSave={handleSave}
                      ></TextEditor>
                      <button
                        className="border border-green-300 bg-white text-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 active:border-orange-600 py-2 px-4 rounded mt-4"
                        onClick={handleRequestChange}
                      >
                        Request Changes
                      </button>
                      <span className="mx-2">
                        <InfoIcon
                          infoText={
                            "You can request changes to the chapter. Simply provide comments in the text editor in a format '// add extra paragraph regarding...' or if you modified text with styling or logic You can ask to check the grammar or stick to current layout."
                          }
                        />
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div
                        className="innerHtml border border-gray-300 bg-white text-black  py-2 px-4 rounded mt-4"
                        dangerouslySetInnerHTML={{ __html: chapterContent }}
                      ></div>
                      <button
                        className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
                        onClick={handleEdit}
                      >
                        Edit
                      </button>
                      <button
                        className="mx-1 border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
                        onClick={hideChapter}
                      >
                        Hide Chapter
                      </button>
                      {chapterIsDone ? (
                        <button
                          className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
                          onClick={changeChapterStatus}
                        >
                          Unmark Chapter as Done
                        </button>
                      ) : (
                        <button
                          className="border border-green-500 bg-white text-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 active:border-green-600 py-2 px-4 rounded mt-4"
                          onClick={changeChapterStatus}
                        >
                          Mark Chapter as Done
                        </button>
                      )}
                      <span className="mx-2">
                        <InfoIcon
                          infoText={
                            "You can click Edit and TextEditor will become available. You can Hide Chapter or Mark Chapter as Done depending on the state of the chapter. When You Mark Chapter as Done, it will be highlighted with green border, so you know you don't have to distract yourself on it anymore. This action is revertable. Note: when you're in viewer mode, You will not see the styling. Don't worry - the styling is there, You just don't see it in this mode."
                          }
                        />
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        // Display the "Work on Chapter" button if not working on the chapter
        <button
          className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 rounded mt-4"
          onClick={handleWorkOnChapter}
        >
          Work on Chapter
        </button>
      )}
    </div>
  );
};

export default Chapter;
