// Question.js

import React from "react";

const Question = ({ question, handleResponse, answer }) => {
  const handleTextAreaChange = (e) => {
    const text = e.target.value;
    handleResponse(text); // Call the handleResponse function to update the answer
  };

  return (
    <div>
      <p className="text-orange-500 mt-4">{question}</p>
      {/* Render a text area */}
      <textarea
        className="border border-gray-300 focus:border-orange-500  bg-white py-2 px-4 rounded mt-1 w-full"
        name="response"
        placeholder="Enter your answer here..."
        value={answer}
        onChange={handleTextAreaChange}
        style={{ outline: "orange" }}
      ></textarea>
    </div>
  );
};

export default Question;
