import React from "react";
import ProfilePopup from "../components/ProfilePopup";
import ChatUser from "../components/ChatUser";

// const socket = io(APIRoutes.socket); // Adjust the URL to your WebSocket server

const SupportPage = ({ isProfilePopupOpen, onCloseProfile }) => {
  return (
    <div className="relative bg-gradient-to-b from-orange-500 via-orange-300 to-orange-500 flex flex-col items-center justify-center min-h-screen">
      {isProfilePopupOpen ? (
        <div className="grid grid-cols-3 flex-col items-center w-screen p-8 h-full space-x-8">
          <div className="space-y-8 col-span-2 row-span-auto bg-white rounded-lg p-8 flex flex-col items-center justify-center max-h-screen">
            <ChatUser />
          </div>
          <ProfilePopup onClose={onCloseProfile} />
        </div>
      ) : (
        <div className="w-3/4 bg-white p-8 rounded flex flex-col items-center justify-center max-h-screen my-4">
          <ChatUser />
        </div>
      )}
    </div>
  );
};

export default SupportPage;
