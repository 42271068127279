import React from "react";

const InfoIcon = ({ infoText }) => {
  return (
    <div className="relative inline-block cursor-pointer group">
      <span className="text-xl text-orange-400 group-hover:text-blue-500">
        &#9432;
      </span>
      <div className="w-80 hidden group-hover:block absolute top-full left-1/2 transform -translate-x-1/2 bg-white border border-orange-300 rounded p-2 shadow-md z-50">
        <p className="text-sm">{infoText}</p>
      </div>
    </div>
  );
};

export default InfoIcon;
