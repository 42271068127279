import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIRoutes from "../api/apiRoutes";
import ChapterList from "../components/ChapterList";
import TestPlanViewer from "../components/TestplanViewer";
import useAxiosInterceptors from "../components/login/Unauthorized";
import TestPlanInfo from "../components/TestplanInfo";
import ProfilePopup from "../components/ProfilePopup";
import InfoIcon from "../components/modals/InfoIcon";
import LoadingSpinner from "../components/LoadingSpinner";
import TypingEffect from "../components/TypingEffect";
const TestplanDetail = ({ isProfilePopupOpen, onCloseProfile }) => {
  useAxiosInterceptors();
  const { id } = useParams();
  const [testPlan, setTestPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDashboard, setShowDashboard] = useState(false);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    // Fetch the data for the specified test plan ID
    fetch(`${APIRoutes.getTestplans}/${id}`, {
      headers: {
        Authorization: `${accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch test plan.");
        }
      })
      .then((data) => {
        setTestPlan(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching test plan:", error);
        setIsLoading(false);
      });
  }, [id]);

  const handleStartDashboard = () => {
    if (showDashboard) {
      setShowDashboard(false);
      window.location.reload();
    } else {
      setShowDashboard(true);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner></LoadingSpinner>
        <TypingEffect
          message="Loading testplan details..."
          speed={100}
          loop={true}
        />
      </div>
    );
  }

  if (!testPlan) {
    return <div>Test plan not found.</div>;
  }

  return (
    <div className="relative bg-gradient-to-b from-orange-500 via-orange-300 to-orange-500 flex flex-col items-center justify-start min-h-screen">
      {isProfilePopupOpen ? (
        <div className="grid grid-cols-3 flex-col items-center w-screen p-8 h-full space-x-8">
          <div className="space-y-8 col-span-2 row-span-full min-h-max  h-full">
            <div className=" bg-white rounded-lg p-8 flex flex-col items-center justify-center">
              <h3 className="text-2xl font-bold text-gray-800">
                Testplan Details
              </h3>{" "}
              <div className="">
                <TestPlanInfo
                  testPlan={testPlan}
                  isLink={false}
                  isEditable={true}
                ></TestPlanInfo>
                {!showDashboard ? (
                  <div className="w-full">
                    <button
                      className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600  py-2 px-4 rounded mt-4"
                      onClick={handleStartDashboard}
                    >
                      Edit Chapters
                    </button>
                    <span className="ml-2">
                      <InfoIcon
                        infoText={
                          "Test plan generation is done by generating each chapter separately and then collecting results into one document. So, if you're ready to start - click Edit Chapters."
                        }
                      />
                    </span>
                    <TestPlanViewer testPlan={testPlan} />
                  </div>
                ) : (
                  <div className="w-full">
                    <button
                      className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600  py-2 px-4 rounded mt-4"
                      onClick={handleStartDashboard}
                    >
                      Finish Editing
                    </button>
                    <div className="mt-6">
                      <ChapterList testplan={testPlan} testPlanId={id} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ProfilePopup onClose={onCloseProfile} />
        </div>
      ) : (
        <div className="w-2/3 bg-white rounded-lg p-8 flex flex-col items-center justify-center space-y-4 my-8">
          <h3 className="text-2xl font-bold text-gray-800">Testplan Details</h3>
          <TestPlanInfo
            testPlan={testPlan}
            isLink={false}
            isEditable={true}
          ></TestPlanInfo>

          {!showDashboard ? (
            <div className="w-full">
              <button
                className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600  py-2 px-4 rounded mt-4"
                onClick={handleStartDashboard}
              >
                Edit Chapters
              </button>
              <span className="ml-2">
                <InfoIcon
                  infoText={
                    "Test plan generation is done by generating each chapter separately and then collecting results into one document. So, if you're ready to start - click Edit Chapters."
                  }
                />
              </span>

              <TestPlanViewer testPlan={testPlan} />
            </div>
          ) : (
            <div className="w-full">
              <button
                className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600  py-2 px-4 rounded mt-4"
                onClick={handleStartDashboard}
              >
                Finish Editing
              </button>
              <span className="ml-2">
                <InfoIcon
                  infoText={
                    "Below You can see Chapter List. CLick Work on Chapter if you want to generate one. In case you're ready to see the full testplan, click Finish Editing."
                  }
                />
              </span>
              <div className="mt-6">
                <ChapterList testplan={testPlan} testPlanId={id} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TestplanDetail;
