// components/BillingPage.jsx
import React from "react";
import ProfilePopup from "../components/ProfilePopup";
import SubscriptionList from "../components/SubscriptionList";

const BillingPage = ({ isProfilePopupOpen, onCloseProfile }) => {
  return (
    <div className="relative bg-gradient-to-b from-orange-500 via-orange-300 to-orange-500 flex flex-col items-center justify-center min-h-screen">
      {isProfilePopupOpen ? (
        <div className="grid grid-cols-3 flex-col items-center w-screen p-8 h-full space-x-8">
          <div className="space-y-8 col-span-2 row-span-auto bg-white rounded-lg p-8 flex flex-col items-center justify-center h-full">
            <SubscriptionList />
          </div>
          <ProfilePopup onClose={onCloseProfile} />
        </div>
      ) : (
        <div className="my-8 w-2/3 bg-white p-8 rounded flex flex-col items-center justify-center">
          <SubscriptionList />
        </div>
      )}
    </div>
  );
};

export default BillingPage;
