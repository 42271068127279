import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import APIRoutes from "../../api/apiRoutes";
const accessToken = localStorage.getItem("accessToken");

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

function PayPal() {
  const initialOptions = {
    //sandbox "client-id": "AXFg4_sSJdU0GSRsCct9L-ZQrxlLCR4k3v7vG9_JJ2wCtsI872ntfhjyOjDdcxatIvJvrzM6dp0tQfhE",
    "client-id":
      "AcAokd-ML9honKuize60Dg2QeViDPwkl1XSq_gfnFar5DU8S0m0ZpKkdb3Sa72GA_SqYmYR3fF1AY5zl",
    "enable-funding": "paylater,card",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };

  const [message, setMessage] = useState("");

  return (
    <div className="App flex justify-center mt-6">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "pill",
            layout: "vertical",
            color: "white",
            label: "subscribe",
          }}
          createSubscription={async () => {
            try {
              const response = await fetch(
                `${APIRoutes.paypalCreateSubscription}`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: accessToken,
                  },
                  body: JSON.stringify({
                    userAction: "SUBSCRIBE_NOW",
                  }),
                }
              );
              const data = await response.json();
              if (data?.id) {
                //setMessage(`Successful subscription...`);
                return data.id;
              } else {
                console.error(
                  { callback: "createSubscription", serverResponse: data },
                  JSON.stringify(data, null, 2)
                );
                // (Optional) The following hides the button container and shows a message about why checkout can't be initiated
                const errorDetail = data?.details?.[0];
                setMessage(
                  `Could not initiate PayPal Subscription...<br><br>${
                    errorDetail?.issue || ""
                  } ${errorDetail?.description || data?.message || ""} ` +
                    (data?.debug_id ? `(${data.debug_id})` : ""),
                  { hideButtons: true }
                );
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Subscription...${error}`);
            }
          }}
          onApprove={async (data, actions) => {
            try {
              // Fetch the subscription details
              const subscriptionDetails = await actions.subscription.get();
              // Check if the subscription status is 'ACTIVE'
              if (subscriptionDetails.status === "ACTIVE") {
                // console.log("Subscription activated successfully!");
                setMessage(
                  `You have successfully subscribed to the plan. Your subscription id is: ${subscriptionDetails.id}. Refresh the page to see details.`
                );
              } else {
                // console.log(
                //   "Subscription is not activated yet, this may take a few minutes. Try to reload page. If the problem persists - contact support."
                // );
                setMessage(
                  `Subscription is not activated yet, this may take a few minutes. Try to reload page. If the problem persists - contact support. Your Subscription ID: ${subscriptionDetails.id}`
                );
              }
            } catch (error) {
              console.error("Error fetching subscription details:", error);
              setMessage(
                `Failed to activate the subscription: ${error.message}`
              );
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default PayPal;
