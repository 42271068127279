import React, { useState, useEffect } from "react";
import axios from "axios";
import APIRoutes from "../api/apiRoutes";
import TestplanList from "../components/TestplanList";
import NewTestPlanForm from "../components/NewTestPlanForm";
import useAxiosInterceptors from "../components/login/Unauthorized";
import ProfilePopup from "../components/ProfilePopup";
import { useUser } from "../context/UserDataContext";

const MyTestplans = ({ isProfilePopupOpen, onCloseProfile }) => {
  const { testPlans, setTestPlans, fetchTestPlans } = useUser();
  const [showNewTestplanForm, setShowNewTestplanForm] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  useAxiosInterceptors();

  useEffect(() => {
    const getTestPlans = () => {
      if (accessToken) {
        fetchTestPlans(accessToken);
      }
    };
    getTestPlans();
  }, []);

  const handleCreateTestPlan = (newTestPlan) => {
    // Add logic to handle the created test plan, e.g., send it to the server
    // console.log("New test plan created:", newTestPlan);

    // Hide the NewTestPlanForm after test plan creation
    setShowNewTestplanForm(false);
    setTestPlans([...testPlans, newTestPlan]);
  };

  const handleStartNewTestPlan = () => {
    if (showNewTestplanForm) {
      setShowNewTestplanForm(false);
    }
    setShowNewTestplanForm(true);
  };

  const handleDeleteTestPlan = async (testPlanId) => {
    try {
      // Send a request to delete the test plan by its ID
      const response = await fetch(
        `${APIRoutes.deleteTestplan}/${testPlanId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // If the deletion is successful, update the list of test plans
        setTestPlans(
          testPlans.filter((testPlan) => testPlan._id !== testPlanId)
        );
      } else {
        console.error("Failed to delete the test plan.");
      }
    } catch (error) {
      console.error("Error deleting the test plan:", error);
    }
  };

  return (
    <div className="relative bg-gradient-to-b from-orange-500 via-orange-300 to-orange-500 flex flex-col items-center justify-center min-h-screen">
      {isProfilePopupOpen ? (
        <div className="grid grid-cols-3 flex-col items-center w-screen p-8 h-full space-x-8">
          <div className="space-y-8 col-span-2 row-span-full min-h-max  h-full">
            <div className=" bg-white rounded-lg p-8 flex flex-col items-center justify-center">
              <h1 className="text-4xl font-bold text-gray-800 ">Welcome!</h1>
              <button
                className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 mt-4 rounded"
                onClick={
                  showNewTestplanForm
                    ? () => setShowNewTestplanForm(false)
                    : handleStartNewTestPlan
                }
              >
                {showNewTestplanForm
                  ? "Hide Form"
                  : "Start with a new test plan"}
              </button>
              {showNewTestplanForm && (
                <div className="w-full">
                  {/* Extracted Box for Form */}
                  <NewTestPlanForm
                    onCreateTestPlan={handleCreateTestPlan}
                    onClose={() => setShowNewTestplanForm(false)}
                  />
                </div>
              )}
            </div>
            <div className="bg-white rounded-lg p-8 flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <TestplanList
                  testPlans={testPlans}
                  onDeleteTestPlan={handleDeleteTestPlan}
                />
              </div>
            </div>
          </div>

          <ProfilePopup onClose={onCloseProfile} />
        </div>
      ) : (
        <div className="w-2/3 flex flex-col items-center justify-center space-y-4 my-8">
          <div className="w-full bg-white rounded-lg p-8 flex flex-col items-center justify-center ">
            <h1 className="text-4xl font-bold text-gray-800">Welcome!</h1>
            <button
              className="border border-orange-500 bg-white text-orange-500 hover:bg-orange-500 hover:text-white active:bg-orange-600 active:border-orange-600 py-2 px-4 mt-4 rounded"
              onClick={
                showNewTestplanForm
                  ? () => setShowNewTestplanForm(false)
                  : handleStartNewTestPlan
              }
            >
              {showNewTestplanForm ? "Hide Form" : "Start with a new test plan"}
            </button>
            {showNewTestplanForm && (
              <div className="w-full">
                {/* Extracted Box for Form */}
                <NewTestPlanForm
                  onCreateTestPlan={handleCreateTestPlan}
                  onClose={() => setShowNewTestplanForm(false)}
                />
              </div>
            )}
          </div>
          <div className="w-full bg-white rounded-lg p-8 flex flex-col items-center justify-center ">
            {testPlans ? (
              <TestplanList
                testPlans={testPlans}
                onDeleteTestPlan={handleDeleteTestPlan}
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTestplans;
