import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import APIRoutes from "../../api/apiRoutes"; // Import your API routes
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserDataContext";
function Login() {
  const navigate = useNavigate();
  const { fetchUserData } = useUser();

  function handleCallbackResponse(response) {
    // console.log("Response: " + JSON.stringify(response));
    // Assuming you are sending a JSON object in the response
    fetch(APIRoutes.googleUserCheck, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((res) => {
        // console.log("Response Headers:", [...res.headers.entries()]);
        const accessToken = res.headers.get("Authorization");
        localStorage.setItem("accessToken", accessToken);

        // console.log("Access Token res:", accessToken);
        if (res.ok) {
          // console.log("Google registration successful");
          return res; // Return the response object - I RECEIVE TOKEN HERE
        } else {
          // Registration failed
          // console.error("Google registration failed");
          // You can show an error message or handle the failure accordingly
          throw new Error("Registration failed");
        }
      })
      .then((testPlansRes) => {
        // console.log(
        //   "Access Token from localStorage: ",
        //   localStorage.getItem("accessToken")
        // );
        fetchUserData(localStorage.getItem("accessToken"));

        // Use the accessToken for subsequent requests, for example, to "/testplans"
        fetch(APIRoutes.getTestplans, {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        })
          .then((testPlansRes) => {
            if (testPlansRes.ok) {
              // console.log("Test plans retrieved successfully");
              // Handle the test plans data here

              // Navigate to the desired route, for example, '/mytestplans'
              navigate("/testplans");
              //window.location.reload();
            } else if (testPlansRes.status === 403) {
              // console.log(
              //   "User is not authorized to access test plans - Redirecting to billing page"
              // );
              // Redirect to the billing page
              navigate("/billing");
            } else {
              console.error("Failed to retrieve test plans");
              // Handle other HTTP errors here
            }
          })
          .catch((error) => {
            console.error("Error retrieving test plans:", error);
          });
      })
      .catch((error) => {
        console.error("Error during Google registration:", error);
        // Handle any network or server-related errors here
      });
  }

  return (
    <div className="relative">
      {/* Welcome Text */}
      <p className="text-gray-600 mb-4 text-center">
        Sign in to create test plans effortlessly within a few hours.
      </p>
      {/* Google Sign In Button */}
      <div className="bg-white p-4 flex flex-col items-center justify-center">
        <GoogleOAuthProvider clientId="267183868621-f2o2f0v19jslah903kqm37t11gvc5b1o.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={handleCallbackResponse}
            onError={() => console.error("Google registration failed")}
            theme="outline"
          >
            button
          </GoogleLogin>
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}
export default Login;
