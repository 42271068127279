// Import the chapters from the JSON file
const chapters = require("./testplanStructure.json");

export default function generateTestPlan(
  projectName,
  testPlanType, // Accept "func", "non-func", or "general"
  projectType, // Accept "internal" or "external"
  externalCompanyName,
  briefDescription // Accept the external company name as a string
) {
  const creationDate = new Date(Date.now());

  const testPlanChapters = [];
  const description = briefDescription;

  if (testPlanType === "functional") {
    testPlanChapters.push(...chapters.functionalTestplan.chapters);
  } else if (testPlanType === "non-functional") {
    testPlanChapters.push(...chapters.nonFunctionalTestplan.chapters);
  } else {
    testPlanChapters.push(...chapters.generalTestplan.chapters);
  }
  const newTestPlan = {
    projectName,
    projectType,
    externalCompanyName,
    creationDate: creationDate.toLocaleString(),
    chapters: testPlanChapters,
    description,
  };
  console.log(newTestPlan);

  return newTestPlan;
}
