import React, { useState, useEffect } from "react";

const TypingEffect = ({ message, speed, loop }) => {
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const typeNextCharacter = () => {
      if (index < message.length) {
        setText((prevText) => prevText + message.charAt(index));
        setIndex((prevIndex) => prevIndex + 1);
      } else if (loop) {
        // Reset index to 0 when the end of the message is reached and loop is true
        setIndex(0);
        setText("");
      }
    };

    const timeoutId = setInterval(typeNextCharacter, speed);

    return () => clearInterval(timeoutId);
  }, [index, message, speed, loop]);

  return <span className="mx-1">{text}</span>;
};

export default TypingEffect;
